import {affiliateService, cmsService} from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { SHARED } from '@/constants/constants'

//define module name
const MODULE_NAME = 'affiliate/'

//define actions name
const A_DETAIL = 'getDetail'
const A_MEMBER_LIST = 'getMemberList'
const A_MEMBER_DETAIL_REPORT = 'getMemberDetailReport'
const A_PRODUCT_REPORT = 'getProductReport'
const A_PRODUCT_DETAIL_REPORT = 'getProductDetailReport'
const A_QUICK_LINK = 'getQuickLink'
const A_AGENT_REPORT = 'getAgentReport'
const A_REFERRAL_CODE = 'getReferralCode'
const A_UPGRADE_TYPE = 'upgradeAffilateType'
const A_RESET_UPGRADE_TYPE_STORE = 'resetUpgradeAffilaiteStore'
const A_LEVEL_TYPE_LOG = 'getAffiliateLevelTypeLog'
const A_TRANSFER_HISTORY = 'getAffiliateTransferHistory'
const A_AGENT_PRODUCT_PRODUCT_DOWNLINE_REPORT = 'getAgentProductDownlineReport'
const A_REGISTER_PAGE_BANNER = 'getRegisterPageBanner'
const A_IS_CUSTOM_REGISTER_PAGE_BANNER = 'isCustomRegisterPageBanner'
//define dispatch action
export const AFFILIATE_DETAIL = MODULE_NAME + A_DETAIL
export const AFFILIATE_MEMBER_LIST = MODULE_NAME + A_MEMBER_LIST
export const AFFILIATE_MEMBER_DETAIL_REPORT = MODULE_NAME + A_MEMBER_DETAIL_REPORT
export const AFFILIATE_PRODUCT_REPORT = MODULE_NAME + A_PRODUCT_REPORT
export const AFFILIATE_PRODUCT_DETAIL_REPORT = MODULE_NAME + A_PRODUCT_DETAIL_REPORT
export const AFFILIATE_QUICK_LINK = MODULE_NAME + A_QUICK_LINK
export const AFFILIATE_AGENT_REPORT = MODULE_NAME + A_AGENT_REPORT
export const AFFILIATE_REFERRAL_CODE = MODULE_NAME + A_REFERRAL_CODE
export const AFFILIATE_UPGRADE_TYPE = MODULE_NAME + A_UPGRADE_TYPE
export const AFFILIATE_RESET_UPGRADE_TYPE_STORE = MODULE_NAME + A_RESET_UPGRADE_TYPE_STORE
export const AFFILIATE_LEVEL_TYPE_LOG = MODULE_NAME + A_LEVEL_TYPE_LOG
export const AFFILIATE_TRANSFER_HISTORY = MODULE_NAME + A_TRANSFER_HISTORY
export const AFFILIATE_AGENT_PRODUCT_DOWNLINE_REPORT = MODULE_NAME + A_AGENT_PRODUCT_PRODUCT_DOWNLINE_REPORT
export const AFFILIATE_REGISTER_PAGE_BANNER = MODULE_NAME + A_REGISTER_PAGE_BANNER
export const AFFILIATE_IS_CUSTOM_REGISTER_PAGE_BANNER = MODULE_NAME + A_IS_CUSTOM_REGISTER_PAGE_BANNER
//define mutations name
const M_FAILURE = 'setFailure'
const M_DETAIL = 'setDetail'
const M_MEMBER_LIST = 'setMemberList'
const M_MEMBER_DETAIL_REPORT = 'setMemberDetailReport'
const M_PRODUCT_REPORT = 'setProductReport'
const M_PRODUCT_DETAIL_REPORT = 'setProductDetailReport'
const M_QUICK_LINK = 'setQuickLink'
const M_AGENT_REPORT = 'setAgentReport'
const M_REFERRAL_CODE = 'setReferralCode'
const M_UPGRADE_TYPE = 'upgradeAffilaiteComplete'
const M_RESET_UPGRADE_TYPE_STORE = 'resetUpgradeAffilaiteStoreComplete'
const M_LEVEL_TYPE_LOG = 'setAffiliateLevelTypeLog'
const M_TRANSFER_HISTORY = 'setAffiliateTransferHistory'
const M_AGENT_PRODUCT_PRODUCT_DOWNLINE_REPORT = 'setAgentProductDownlineReport'
const M_AGENT_REGISTER_PAGE_BANNER = 'setRegisterPageBanner'
const M_IS_CUSTOM_REGISTER_PAGE_BANNER = 'setIsCustomRegisterPageBanner'

//init app state
const state = {
  referralCode: '',
  referralLink: [],
  member: {
    list: [],
    lastUpdatedDate: '',
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      totalItems: 0
    }
  },
  memberDetail: {
    list: [],
    lastUpdatedDate: '',
    pageSummary: {},
    recordSummary: {},
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      totalItems: 0
    }
  },
  gameDetail: {
    list: [],
    lastUpdatedDate: '',
    recordSummary: {},
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      totalItems: 0
    }
  },
  agentDetail: {
    list: [],
    lastUpdatedDate: '',
    pageSummary: {},
    recordSummary: {},
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      totalItems: 0
    }
  },
  detail: {
    walletBalance: 0,
    referralCode: '',
    affiliateTypeId: '',
    affiliateTierId: ''
  },
  upgradeAffiliateTypeResponse: {
    action: 'upgradeAffilaiteType',
    complete: false,
    success: false,
    code: 0
  },
  latestLevelTypeLog: {},
  history: [],
  pagination: {
    pageNumber: 0,
    pageSize: 0,
    lastPage: 0,
    tableRowsCount: 0
  },
  errorMsg: '',
  registerPageBanner: {
    complete: false,
    status: false,
    code: 0,
    data: {},
    isCustomRegisterPageBanner: false,
  }
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_DETAIL]({ dispatch, commit }, { requiredRefresh }) {
    if (requiredRefresh) {
      dispatch(`${SHARED_LOADING}`, {}, { root: true })
    }

    affiliateService.getDetails().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DETAIL, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_MEMBER_LIST]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getMemberReport(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_MEMBER_LIST, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_MEMBER_DETAIL_REPORT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getMemberDetailReport(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_MEMBER_DETAIL_REPORT, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_PRODUCT_REPORT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getProductReport(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_PRODUCT_REPORT, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_PRODUCT_DETAIL_REPORT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getProductDetailReport(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_PRODUCT_DETAIL_REPORT, { result, obj })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_QUICK_LINK]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getQuickLink(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_QUICK_LINK, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_AGENT_REPORT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getAgentReport(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_AGENT_REPORT, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  async [A_REFERRAL_CODE]({ commit }, { obj }) {
    await affiliateService.getReferralCode(obj).then(
      data => {
        let result = data
        commit(M_REFERRAL_CODE, { result })
      },
      error => {
        commit(M_FAILURE, error)
      }
    )
  },
  [A_UPGRADE_TYPE]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.upgradeAffiliateType().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPGRADE_TYPE, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPGRADE_TYPE, { result })
      }
    )
  },
  [A_RESET_UPGRADE_TYPE_STORE]({ commit }) {
    commit(M_RESET_UPGRADE_TYPE_STORE)
  },
  [A_LEVEL_TYPE_LOG]({ commit }) {
    affiliateService.getAffiliateTypeLog().then(
      data => {
        let result = data
        commit(M_LEVEL_TYPE_LOG, { result })
      },
      error => {
        commit(M_FAILURE, error)
      }
    )
  },
  [A_TRANSFER_HISTORY]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    let isPagination = obj.isPagination
    affiliateService.getAffiliateTransferHistory(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_TRANSFER_HISTORY, { result, isPagination })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_TRANSFER_HISTORY, { result, isPagination })
      }
    )
  },
  [A_AGENT_PRODUCT_PRODUCT_DOWNLINE_REPORT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliateService.getAgentProductDownlineReport(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_AGENT_PRODUCT_PRODUCT_DOWNLINE_REPORT, { result, obj })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_REGISTER_PAGE_BANNER]({ dispatch, commit }, { hash }) {
    cmsService.getRegisterPageBanner(hash).then(
        data => {
          let result = data
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          commit(M_AGENT_REGISTER_PAGE_BANNER, { result })
        },
        error => {
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          commit(M_AGENT_REGISTER_PAGE_BANNER, { result: error })
        }
    )
  },
  [A_IS_CUSTOM_REGISTER_PAGE_BANNER]({ commit }, isCustomRegisterPageBanner) {
    commit(M_IS_CUSTOM_REGISTER_PAGE_BANNER, isCustomRegisterPageBanner)
  }
}

const mutations = {
  [M_DETAIL](state, { result }) {
    if (result.success) {
      let d = result.data
      state.detail = {
        walletBalance: d.wallet_balance,
        referralCode: d.referral_code,
        affiliateTypeId: d.affiliate_type_id,
        affiliateTierId: d.affiliate_tier_id
      }
    }
  },
  [M_MEMBER_LIST](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data

      d.report.forEach((report, index) => {
        p.push({
          index: index,
          memberCode: report.membercode,
          affiliateType: report.affiliate_type,
          registerDate: report.register_date,
          isMemberActive: report.is_member_active
        })
      })
      state.member.lastUpdatedDate = d.last_updated_at
      state.member.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        totalItems: result.pagination.total
      }
    }
    state.member.list = p
  },
  [M_MEMBER_DETAIL_REPORT](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.report.forEach((report, index) => {
        p.push({
          index: index,
          memberCode: report.membercode,
          affiliateType: report.affiliate_type,
          depositAmount: report.deposit_amt,
          withdrawalAmount: report.withdrawal_amt,
          bonusAmount: report.bonus_amt,
          rebateAmount: report.rebate_amt,
          adjustmentAmount: report.adjustment_amt,
          turnover: report.turnover,
          winLoss: report.winloss,
          loginCount: report.login_count
        })
      })
      state.memberDetail.pageSummary = {
        depositAmount: result.count.paging_deposit_amount,
        withdrawalAmount: result.count.paging_withdrawal_amount,
        bonusAmount: result.count.paging_bonus_amount,
        rebateAmount: result.count.paging_rebate_amount,
        adjustmentAmount: result.count.paging_adjustment_amount,
        turnover: result.count.paging_turnover,
        winLoss: result.count.paging_win_loss,
        loginCount: result.count.paging_login_count
      }
      state.memberDetail.recordSummary = {
        depositAmount: result.count.total_deposit_amount,
        withdrawalAmount: result.count.total_withdrawal_amount,
        bonusAmount: result.count.total_bonus_amount,
        rebateAmount: result.count.total_rebate_amount,
        adjustmentAmount: result.count.total_adjustment_amount,
        turnover: result.count.total_turnover,
        winLoss: result.count.total_win_loss,
        loginCount: result.count.total_login_count,
        memberCount: result.count.total_members
      }
      state.memberDetail.lastUpdatedDate = d.last_updated_at
      state.memberDetail.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        totalItems: result.pagination.total
      }
    }
    state.memberDetail.list = p
  },
  [M_PRODUCT_REPORT](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.report.forEach((report, index) => {
        p.push({
          index: index,
          memberCount: report.member_count,
          productType: report.product_type.toLowerCase(),
          turnover: report.turnover,
          winLoss: report.win_loss,
          provider: []
        })
      })

      state.gameDetail.recordSummary = {
        turnover: result.count.total_turnover,
        winLoss: result.count.total_win_loss
      }
      state.gameDetail.lastUpdatedDate = d.last_updated_at
      state.gameDetail.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        totalItems: result.pagination.total
      }

      let sorting = SHARED.MENU_GAME_POSITION
      p.sort(function(a, b) {
        if (sorting.indexOf(a.productType) > sorting.indexOf(b.productType)) {
          return 1
        } else {
          return -1
        }
      })
    }
    state.gameDetail.list = p
  },
  [M_PRODUCT_DETAIL_REPORT](state, { result, obj }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.report.forEach((report, index) => {
        p.push({
          index: index,
          memberCount: report.member_count,
          providerCode: report.product_provider.toLowerCase(),
          turnover: report.turnover,
          winLoss: report.win_loss
        })
      })

      state.gameDetail.list.find(x => x.productType == obj.productType).provider = p
    }
  },
  [M_QUICK_LINK](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data

      d.forEach(link => {
        p.push({
          pageType: link.page_type,
          value: link.page_type,
          link: link.link
        })
      })
    }
    state.referralLink = p
  },
  [M_AGENT_REPORT](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.report.forEach((report, index) => {
        p.push({
          index: index,
          memberCode: report.membercode,
          fullMemberCode: report.full_membercode,
          registeredMembers: report.registered_members,
          activeMembers: report.active_members,
          depositedMembers: report.deposited_members,
          newMembers: report.new_members,
          depositAmount: report.deposit_amt,
          withdrawalAmount: report.withdrawal_amt,
          bonusAmount: report.bonus_amt,
          rebateAmount: report.rebate_amt,
          adjustmentAmount: report.adjustment_amt,
          turnover: report.turnover,
          winLoss: report.winloss,
          productType: []
        })
      })
      state.agentDetail.pageSummary = {
        depositAmount: result.count.paging_deposit_amount,
        withdrawalAmount: result.count.paging_withdrawal_amount,
        bonusAmount: result.count.paging_bonus_amount,
        rebateAmount: result.count.paging_rebate_amount,
        adjustmentAmount: result.count.paging_adjustment_amount,
        turnover: result.count.paging_turnover,
        winLoss: result.count.paging_win_loss
      }
      state.agentDetail.recordSummary = {
        depositAmount: result.count.total_deposit_amount,
        withdrawalAmount: result.count.total_withdrawal_amount,
        bonusAmount: result.count.total_bonus_amount,
        rebateAmount: result.count.total_rebate_amount,
        adjustmentAmount: result.count.total_adjustment_amount,
        turnover: result.count.total_turnover,
        winLoss: result.count.total_win_loss,
        loginCount: result.count.total_login_count,
        memberCount: result.count.total_members
      }
      state.agentDetail.lastUpdatedDate = d.last_updated_at
      state.agentDetail.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        totalItems: result.pagination.total
      }
    }
    state.agentDetail.list = p
  },
  [M_REFERRAL_CODE](state, { result }) {
    let p = ''
    if (result.success) {
      p = result.data
    }
    state.referralCode = p
  },
  [M_UPGRADE_TYPE](state, { result }) {
    state.upgradeAffiliateTypeResponse = {
      action: 'upgradeAffilaiteType',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_RESET_UPGRADE_TYPE_STORE](state) {
    state.updateMemberBankAccountResponse = {
      action: 'upgradeAffilaiteType',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_LEVEL_TYPE_LOG](state, { result }) {
    let p = null
    if (result.success) {
      let d = result.data

      if (d.length > 0) {
        p = {
          fromAffiliateTypeId: d[0].from_aff_type_id,
          toAffiliateTypeId: d[0].to_aff_type,
          approvalStatusId: d[0].approval_status_id,
          remark: d[0].remark
        }
      }
    }
    console.log(p)
    state.latestLevelTypeLog = p
  },
  [M_FAILURE](state, error) {
    state.errorMsg = error
  },

  [M_TRANSFER_HISTORY](state, { result, isPagination }) {
    if (result.success) {
      let d = result.data
      state.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        tableRowsCount: result.pagination.total
      }

      let p = []
      d.forEach(x => {
        p.push({
          transactionId: x.trx_code,
          amount: x.amount,
          status: x.status.toLowerCase() == 'success' ? 'success' : 'fail',
          type: x.type.toLowerCase(),
          fromSource: x.from_wallet,
          toSource: x.to_wallet,
          date: x.created_at,
          actualStatus: x.status.toLowerCase() == 'success' ? 'success' : 'fail'
        })
      })

      if (isPagination) {
        state.history = p
      } else {
        state.history.concat(p)
      }
    }
  },
  [M_AGENT_PRODUCT_PRODUCT_DOWNLINE_REPORT](state, { result, obj }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.report.forEach((report, index) => {
        p.push({
          index: index,
          memberCount: report.member_count,
          productType: report.product_type.toLowerCase(),
          turnover: report.turnover,
          winLoss: report.win_loss
        })
      })
      state.agentDetail.list.find(x => x.fullMemberCode == obj.memberCode).productType = p
      state.agentDetail.list.find(x => x.fullMemberCode == obj.memberCode).productRecordSummary = {
        turnover: result.count.total_turnover,
        winLoss: result.count.total_win_loss
      }
    }
  },
  [M_AGENT_REGISTER_PAGE_BANNER](state, { result }) {
    state.registerPageBanner.complete = true
    state.registerPageBanner.success = result.success
    state.registerPageBanner.code = result.code
    state.registerPageBanner.data = result.data
  },
  [M_IS_CUSTOM_REGISTER_PAGE_BANNER](state, isCustomRegisterPageBanner) {
    state.registerPageBanner.isCustomRegisterPageBanner = isCustomRegisterPageBanner
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
