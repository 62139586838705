<template>
  <div class="page-navigator hidden-sm-and-down">
    <div class="page-navigator-item" v-for="gameType in storageGameProviderType" :key="gameType.type">
      <v-menu open-on-hover bottom offset-y min-width="100% !important" class="mt-2" style="z-index: 15;">
        <template v-slot:activator="{ on }">
          <v-btn
            class="pt-4 pb-2 px-0 page-navigator-button text-capitalize"
            active-class="page-navigator-button--active"
            height="auto"
            text
            :ripple="false"
            light
            depressed
            dark
            v-on="on"
            href
            :to="getListItemTo(gameType)"
            exact
          >
            <v-row no-gutters>
              <v-col cols="12" class="pa-0 text-center">
                <label class="menu-navigator px-1">{{ $t(`label.${gameType.type}`) }}</label>
              </v-col>
              <v-col cols="12" class="px-0 py-1 underline_bar">
                <v-divider class="mx-2 add_border_bold"></v-divider>
              </v-col>
            </v-row>
            <!--            <v-icon v-if="gameType.type != 'fishing' && gameType.type != 'special'">arrow_drop_down</v-icon>-->
            <!-- <v-divider
                                          class="mx-3 gold-color"
                                          vertical
                                          style="border-width: 0 2px 0 0; max-height: 35px;"
                                  ></v-divider> -->
          </v-btn>
        </template>
        <div class="page-sub-navigator elevation-2">
          <div class="page-sub-navigator-wrapper">
            <div class="page-sub-navigator-item" v-for="provider in gameType.providers" :key="provider.providerCode">
              <v-card class="elevation-0 game_card text-center align-center mr-0">
                <!--                <v-card-text class="font-weight-bold vendor-text text-center" style="top: 178px">-->
                <!--                  {{ stringFormat('{0}', $t(`gameVendor.${provider.providerCode}_${gameType.type}_long`)) }}-->
                <!--                  &lt;!&ndash; {{subMenu[provider.providerCode]}} &ndash;&gt;-->
                <!--                </v-card-text>-->
                <v-hover v-slot="{ hover }" open-delay="0">
                  <v-img
                    :aspect-ratio="384 / 300"
                    @click="accessGame(gameType.type, provider.providerCode)"
                    class="item-image"
                    contain
                    :src="`${mediaUrl}/menu/${gameType.type}/${provider.providerCode}_new.png`"
                    :alt="`${+provider.providerCode}`"
                    @mouseover="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, true)"
                    @mouseleave="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, false)"
                  />
                </v-hover>
                <div class="item-image item-maintenance" v-if="isGameMaintenance(gameType.type, provider.providerCode)">
                  <span class="item-maintenance-label white--text">{{ $t(`label.underMaintenance`) }}</span>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </v-menu>
      <!--      <v-menu open-on-hover bottom offset-y min-width="100%" class="mt-2">-->
      <!--        <template v-slot:activator="{ on }">-->
      <!--          <v-btn-->
      <!--            class="pt-4 pb-2 px-0 page-navigator-button text-capitalize"-->
      <!--            active-class="page-navigator-button&#45;&#45;active"-->
      <!--            height="auto"-->
      <!--            text-->
      <!--            :ripple="false"-->
      <!--            light-->
      <!--            depressed-->
      <!--            dark-->
      <!--            v-on="on"-->
      <!--            href-->
      <!--            :to="getListItemTo(gameType)"-->
      <!--            exact-->
      <!--          >-->
      <!--            <v-row>-->
      <!--              <v-col cols="12" class="pa-0 text-center">-->
      <!--                <label class="menu-navigator px-1">{{ $t(`label.${gameType.type}`) }}</label>-->
      <!--              </v-col>-->
      <!--              <v-col cols="12" class="px-0 py-1 underline_bar">-->
      <!--                <v-divider class="mx-2 add_border_bold"></v-divider>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--            &lt;!&ndash;            <v-icon v-if="gameType.type != 'fishing' && gameType.type != 'special'">arrow_drop_down</v-icon>&ndash;&gt;-->
      <!--            &lt;!&ndash; <v-divider-->
      <!--                                class="mx-3 gold-color"-->
      <!--                                vertical-->
      <!--                                style="border-width: 0 2px 0 0; max-height: 35px;"-->
      <!--                        ></v-divider> &ndash;&gt;-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--        <div class="page-sub-navigator elevation-2">-->
      <!--          <div class="page-sub-navigator-item" v-for="provider in gameType.providers" :key="provider.providerCode">-->
      <!--            <v-card flat class="elevation-0 game_card text-center">-->
      <!--              &lt;!&ndash; <v-img class="item-image-icon mx-auto mb-2" contain-->
      <!--                     :src="`/static/image/game/icon/${provider.providerCode}.png`"></v-img> &ndash;&gt;-->
      <!--              <div style="position:relative">-->
      <!--                &lt;!&ndash; <v-img :aspect-ratio="384/372" @click="accessGame(gameType.type, provider.providerCode)" class="item-image mx-auto" contain-->
      <!--                :src="`${mediaUrl}/navigator/${gameType.type}/${provider.providerCode}.png`> &ndash;&gt;-->
      <!--                <v-avatar size="128" class="game_card_avatar mt-4" @click="accessGame(gameType.type, provider.providerCode)">-->
      <!--                  <img-->
      <!--                    :src="`${mediaUrl}/menu/${gameType.type}/${provider.providerCode}.png`"-->
      <!--                    :alt="'babu88-' + provider.providerCode"-->
      <!--                    @mouseover="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, true)"-->
      <!--                    @mouseleave="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, false)"-->
      <!--                  />-->
      <!--                </v-avatar>-->
      <!--                &lt;!&ndash; <v-col cols="12" :class="['text_hover', gameType.type + '_nav']">-->
      <!--                    {{$t(`gameVendor.${provider.providerCode}_long`)}}-->
      <!--                    <img :src="`${mediaUrl}/category_icons/new_icon.png`" v-if="provider.providerCode == 'one'" alt="BABU88 one game" width="45" />-->
      <!--                </v-col> &ndash;&gt;-->
      <!--                &lt;!&ndash; </v-img> &ndash;&gt;-->
      <!--                <div class="item-image item-maintenance" v-if="isGameMaintenance(gameType.type, provider.providerCode)">-->
      <!--                  <span class="item-maintenance-label white&#45;&#45;text">{{ $t(`label.underMaintenance`) }}</span>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <v-card-text class="white&#45;&#45;text font-weight-bold vendor-text text-capitalize text-center">-->
      <!--                {{ stringFormat('{0}', $t(`gameVendor.${provider.providerCode}_${gameType.type}_long`)) }}-->
      <!--                &lt;!&ndash; {{subMenu[provider.providerCode]}} &ndash;&gt;-->
      <!--              </v-card-text>-->
      <!--            </v-card>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </v-menu>-->
    </div>
    <div class="page-navigator-item">
      <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href :to="{ name: routeName.PROMOTION, params: { lang: this.$route.params.lang } }">
        <v-row no-gutters>
          <v-col cols="12" class="pa-0 text-center">
            <label class="px-2">{{ $t(`label.promotion`) }}</label>
          </v-col>
          <v-col cols="12" class="px-0 py-1 underline_bar">
            <v-divider class="mx-2 add_border_bold"></v-divider>
          </v-col>
        </v-row>
      </v-btn>
    </div>
    <div class="page-navigator-item">
      <v-badge right overlap color="light" offset-x="35" offset-y="4">
        <template v-slot:badge>
          <v-img class="img-hot-home" src="/static/image/other/hot-icon.png" width="35" height="25"></v-img>
        </template>
        <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href :to="{ name: routeName.VIP, params: { lang: this.$route.params.lang } }">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 text-center">
              <label class="px-2">{{ $t(`label.vip`) }}</label>
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
    <div class="page-navigator-item">
      <v-badge right overlap color="light" offset-x="35" offset-y="4">
        <template v-slot:badge>
          <v-img class="img-hot-home" src="/static/image/other/hot-icon.png" width="35" height="25"></v-img>
        </template>
        <v-btn
          class="pt-4 pb-2 px-0 page-navigator-button"
          height="auto"
          text
          :ripple="false"
          light
          depressed
          dark
          href
          :to="{ name: isLoggedIn ? routeName.PROFILE_REFERRAL_TIER : routeName.REFERRAL_PREVIEW, params: { lang: this.$route.params.lang } }"
        >
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 text-center">
              <!-- <v-badge color="red accent-4" content="New"> -->
              <label class="px-2">{{ $t(`label.referral`) }}</label>
              <!-- </v-badge> -->
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
    <div class="page-navigator-item" v-if="currentCurrency === 'BDT'">
      <v-badge right overlap color="light" offset-x="11" offset-y="4">
        <template v-slot:badge>
          <v-img src="/static/svg/game-icon-new.svg" width="25" height="25"></v-img>
        </template>
        <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href :to="{ name: routeName.VIPTIER, params: { lang: this.$route.params.lang } }">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 text-center">
              <label class="px-2">{{ $t(`label.vipTier`) }}</label>
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
    <div class="page-navigator-item" v-if="isIPLEventActive">
      <v-badge right overlap color="light" offset-x="35" offset-y="4">
        <template v-slot:badge>
          <v-img class="img-hot-home" src="/static/image/other/hot-icon.png" width="35" height="25"></v-img>
        </template>
        <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark href :to="{ name: routeName.IPLVIP, params: { lang: this.$route.params.lang } }">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 text-center">
              <label class="px-2">{{ $t(`label.iplVIP`) }}</label>
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
    <div class="page-navigator-item">
      <v-btn class="pt-4 pb-2 px-0 page-navigator-button" height="auto" text :ripple="false" light depressed dark :href="agentURL" target="_blank">
        <v-row no-gutters>
          <v-col cols="12" class="pa-0 text-center">
            <!-- <v-badge color="red accent-4" content="New"> -->
            <label class="px-2">{{ $t(`mobileMenuTitle.agentAffiliate`) }}</label>
            <!-- </v-badge> -->
          </v-col>
          <v-col cols="12" class="px-0 py-1 underline_bar">
            <v-divider class="mx-2 add_border_bold"></v-divider>
          </v-col>
        </v-row>
      </v-btn>
    </div>
    <div class="page-navigator-item" v-if="currentCurrency.toUpperCase() === 'BDT'">
      <v-badge right overlap color="light" offset-x="25" offset-y="4">
        <template v-slot:badge>
          <v-img src="/static/svg/game-icon-new.svg" width="25" height="25"></v-img>
        </template>
        <v-btn
          class="pt-4 pb-2 px-0 page-navigator-button"
          height="auto"
          text
          :ripple="false"
          light
          depressed
          dark
          href
          :to="{ name: routeName.REWARD_STORE_ITEM, params: { lang: this.$route.params.lang } }"
        >
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 text-center">
              <!-- <v-badge color="red accent-4" content="New"> -->
              <label class="px-2">{{ $t(`mobileMenuTitle.rewards`) }}</label>
              <!-- </v-badge> -->
            </v-col>
            <v-col cols="12" class="px-0 py-1 underline_bar">
              <v-divider class="mx-2 add_border_bold"></v-divider>
            </v-col>
          </v-row>
        </v-btn>
      </v-badge>
    </div>
  </div>
</template>

<script>
import format from 'string-format'
import { CACHE_KEY } from '@/constants/constants'
import { uiHelper } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
import { GAME_LAUNCH_GAME, GAME_RESET_LAUNCH_GAME_RESPONSE, GAME_CASINO_GAMES } from '@/store/game.module'
import { SHARED } from '@/constants/constants'
import { SEMI_TRANSFER } from '@/store/transfer.module'
import { GET_IPL_EVENT_STATUS } from '@/store/member.module'

export default {
  name: 'AppPageNavigator',
  data: () => ({
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    routeName: ROUTE_NAME,
    stringFormat: format,
    test: [],
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    hover: {},
    isIplActive: true,
    currentCurrency: uiHelper.getCurrency(),
    agentURL: uiHelper.getaffLink()
  }),
  created() {},
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    isIPLEventActive() {
      return this.$store.state.member.iplEventDate.status
    }
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    }
  },
  methods: {
    getListItemTo(gameType) {
      let routeName = ''
      switch (gameType.type) {
        case 'sb':
          routeName = ROUTE_NAME.SPORTSBOOK
          break
        case 'rng':
          routeName = ROUTE_NAME.SLOT
          break
        case 'fishing':
          routeName = ROUTE_NAME.FISHING
          break
        case 'special':
          routeName = ROUTE_NAME.SPECIAL
          break
        case 'ld':
          routeName = ROUTE_NAME.CASINO
          break
        case 'table':
          routeName = ROUTE_NAME.TABLE_GAMES
          break
        case 'lottery':
          routeName = ROUTE_NAME.LOTTERY
          break
        case 'crash':
          routeName = ROUTE_NAME.CRASH
          break
        case 'cricket':
          routeName = ROUTE_NAME.CRICKET
          break
        default:
          routeName = ROUTE_NAME.HOME
          break
      }
      if (this.$route.params.lang) {
        // If it has a value, include it in the route
        return { name: routeName, params: { lang: this.$route.params.lang } }
      } else {
        // If not, just return the route without params
        return { name: routeName }
      }
    },
    submenuOnMouseOver(element, gameType, providerCode, mouseover) {
      if (mouseover) {
        element.src = format('{0}/menu/{1}/{2}_hover.png', this.mediaUrl, gameType, providerCode)
      } else {
        element.src = format('{0}/menu/{1}/{2}_new.png', this.mediaUrl, gameType, providerCode)
      }
    },
    navigateToLuckySpin() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'luckyspin',
          lang: this.$route.params.lang
        }
      })
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    async getCasinoProvidersGames() {
      let Obj = {
        currency: uiHelper.getCurrency(),
        type: 'ld',
        size: '',
        page: '',
        platform: uiHelper.getPlatform(),
        category: '',
        provider: '',
        mode: 'All'
      }
      await this.$store.dispatch(`${GAME_CASINO_GAMES}`, {
        Obj
      })
    },
    async launchCasinoGame(type, provider) {
      this.$store.dispatch(`${GAME_RESET_LAUNCH_GAME_RESPONSE}`)
      this.gameUrl = ''
      if (this.Games.find(x => x.provider == provider) != null) {
        let game = this.Games.find(x => x.provider == this.$route.params.provider)
        let gameObj = {
          provider: game.provider,
          gameId: game.id,
          gameCode: game.code,
          isDemo: !this.isLoggedIn,
          platform: uiHelper.getPlatform(),
          language: uiHelper.getLanguage(),
          webSite: uiHelper.getHostname(),
          currency: uiHelper.getCurrency(),
          gameType: game.type
        }
        await this.$store.dispatch(`${GAME_LAUNCH_GAME}`, {
          gameObj
        })
      } else {
        await this.getCasinoProvidersGames()
        this.launchGame()
      }
    },
    async accessGame(type, providerCode) {
      let gaType = ''
      switch (type) {
        case 'sb':
          gaType = 'Sportsbook'
          break
        case 'fishing':
          gaType = 'Fishing'
          break
        case 'ld':
          gaType = 'Casino'
          break
        case 'special':
          gaType = 'Special'
          break
        case 'cricket':
          gaType = 'Cricket'
          break
        case 'crash':
          gaType = 'crash'
          break
      }
      if (type == 'rng') {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'lottery') {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'table') {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'special') {
        this.$router.push({
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'cricket') {
        this.$router.push({
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (type == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else {
        if (this.isLoggedIn) {
          let routeData = this.$router.resolve({
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            params: {
              provider: providerCode,
              type: type,
              lang: this.$route.params.lang
            },
            query: {
              demo: 0
            }
          })
          try {
            this.$ga.event('Game', 'Real Play', `${providerCode.toUpperCase()} - ${gaType}`)
          } catch (err) {
            console.log(err)
          }
          // uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1200, 900)
          if (this.providerSelected != 'sb') {
            this.semiTransfer(providerCode).then(() => {
              uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
            })
          } else {
            await uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
            this.semiTransfer(providerCode)
          }
          uiHelper.pushSportRadarRetargeting(null, type, providerCode)
        } else {
          this.goToLoginPage()
        }
      }
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    },
    semiTransfer(providerCode) {
      let obj = {
        to_wallet: providerCode
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, { obj })
    }
  }
}
</script>

<style>
.v-menu__content {
  top: 206px;
}
</style>

<style lang="scss">
.img-hot-home {
  width: 35px;
  animation: beat 1s infinite alternate;
}

.page-sub-navigator-wrapper {
  position: relative;
  //top: -32px;
  max-height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
}
.page-sub-navigator .page-sub-navigator-wrapper:hover .page-sub-navigator-item {
  filter: grayscale(0.6);
}

.page-sub-navigator .page-sub-navigator-wrapper .page-sub-navigator-item:hover {
  filter: grayscale(0);
}
.rng_nav {
  position: absolute;
  bottom: 15px;
}
.ld_nav {
  position: absolute;
  bottom: 0;
}
.sb_nav {
  position: absolute;
  bottom: -15px;
}
.add_border_bold {
  border: 3px solid;
  border-color: #fddd04 !important;
}
.underline_bar {
  opacity: 0;
}
.page-navigator {
  display: flex;
  justify-content: left;
  padding: 0px 40px;
  width: 100%;
  color: #333333;
  height: auto; //background-image: linear-gradient(to bottom, #2D3387, #24296D, #121734);
  background-color: #333333;
  text-align: left;
  .page-navigator-item {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    .page-navigator-button {
      color: #ffffff; // caret-color: #9C9FF3 !important;
      text-transform: capitalize !important;
      label {
        cursor: pointer;
      }
      &.v-btn--active {
        color: #fddd04 !important;
        &::before {
          opacity: 0;
        }
        .underline_bar {
          opacity: 1;
        }
      }
      &:hover {
        color: #fddd04 !important;
        // caret-color: var(--v-navigator_active-base) !important;
        // &::before {
        //     opacity: 0;
        // }
        // .underline_bar {
        //     opacity: 1;
        // }
      }
    }

    label {
      letter-spacing: 0.36px !important;
      //font-size: 18px;
    }
  }
}
.page-sub-navigator {
  display: table;
  width: 100%;
  text-align: left;
  background-color: #000000bf !important;
  opacity: 0.9 !important;
  .page-sub-navigator-item {
    display: inline-block;
    vertical-align: middle;
    width: 16.6%;
    //margin: 0 13px;
    cursor: pointer;
    .game_card {
      background-color: transparent;
      width: calc(1050px / 6);
      height: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      .game_card_avatar {
        border-radius: 0px !important;
        &:hover {
          //opacity: 50%;
          // border: 2px;
          // border-color: #FDDD04;
          cursor: pointer;
        }
      }
    }
    // &:hover>.game_card {
    //     margin: 20px 0;
    //     border-radius: 10px;
    // }
    &:hover .text_hover {
      color: #9c9ff3;
    }
    &:hover .item-image {
      opacity: 1;
    }
    .vendor-text {
      font-size: 16px;
      padding-top: 4px !important;
      padding: 4px 0px 10px !important;
    }
    //.v-card {
    //  padding: 16px 0;
    //}
    //.item-image {
    //  max-width: 250px;
    //  cursor: pointer;
    //  opacity: 0.5;
    //}
    .item-maintenance {
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      opacity: 1;
      position: absolute;
      z-index: 1;
      border-radius: 25px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      .item-maintenance-label {
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-weight: bold;
      }
    }
    .item-image-icon {
      height: 20px;
    }
  }
}
@media (max-width: 1650px) {
}
@media (max-width: 1500px) {
  .page-sub-navigator .page-sub-navigator-item .v-card {
    padding-top: 12px;
  }
  .page-sub-navigator .page-sub-navigator-item {
    width: 250px;
  }
  .page-sub-navigator .page-sub-navigator-item .item-image {
    max-width: 225px;
  }
}
@media (max-width: 1350px) {
  .page-navigator .page-navigator-item label {
    //font-size: 18px;
  }
}
@media (max-width: 1263px) {
  .page-navigator .page-navigator-item .page-navigator-button label {
    font-size: 12px;
  }
  .page-navigator .page-navigator-item {
    margin: 0 auto;
  }
  .page-sub-navigator .page-sub-navigator-item {
    width: 210px;
  }
  .page-sub-navigator .page-sub-navigator-item .item-image {
    max-width: 195px;
  }
  .page-sub-navigator {
    .page-sub-navigator-item {
      .vendor-text {
        font-size: 12px;
        padding-bottom: 4px !important;
      }
    }
  }
  .page-sub-navigator .page-sub-navigator-item .item-image-icon {
    height: 18px;
  }
}

@media (max-width: 1024px) {
  .page-navigator {
    padding: 0px 30px;
    .page-navigator-item {
      font-size: 13px;
    }
  }
}

@keyframes beat {
  to {
    transform: scale(1.15);
  }
}
</style>
