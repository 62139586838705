<template>
  <div>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="1000" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <div class="game-banner hidden-md-and-down" style="position: relative;">
      <div class="menuBar" v-if="isLoggedIn" style="padding: 0 12%;">
        <div class="menuBarWrapper">
          <app-member-menu-bar></app-member-menu-bar>
        </div>
      </div>
      <!-- <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
              <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
          </v-carousel-item>
      </v-carousel> -->
      <v-img class="align-end" :aspect-ratio="1800 / 650" :src="`${mediaUrl}/game_banner/vip-banner-desktop.jpg`"></v-img>

      <div class="img-vip">
        <v-img class="" width="600" height="160" :src="`${mediaUrl}/game_banner/no1.png`"></v-img>
      </div>
      <div class="text-vip">
        <p class="joinVipText pb-5">{{ $t(`label.joinVip2`) }}</p>
<!--        <p class="joinVipText">{{ $t(`label.joinVip3`) }}</p>-->
      </div>
    </div>
    <div class="vip-tiermobile hidden-lg-and-up">
      <v-img class="align-end" style="border-radius: 15px" :aspect-ratio="360 / 140" :src="`${mediaUrl}/game_banner/VipBanner_desktop.jpg`"></v-img>
      <v-img class="align-end mt-2 pb-3 vip-tier-mobile-banner" style="border-radius: 15px" :aspect-ratio="400 / 640" :src="`${mediaUrl}/game_banner/vip-banner-mobile.jpg`">
        <div class="text-container">
          <v-row style="" no-gutters class="d-flex justify-center align-end px-3">
            <v-row no-gutters class="beVipText">
              <v-col cols="12" class="pa-0 d-flex align-center justify-center">
                <span class="white--text text-center">
                  <h3 class="headertext">{{ $t(`label.beVip`) }}</h3>
                </span>
              </v-col>
              <v-col cols="12" class="pa-0 d-flex align-center justify-center">
                <span class="white--text text-center">
                  <p class="descriptionVipText">{{ $t(`label.beVipDes1`) }}</p>
                  <p class="descriptionVipText">{{ $t(`label.beVipDes2`) }}</p>
                  <p class="descriptionVipText">{{ $t(`label.beVipDes3`) }}</p>
                </span>
              </v-col>
              <v-col cols="12" class="pa-0 d-flex align-center justify-center">
                <span class="white--text text-center" style="font-size: 14px">
                  <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes5`) }}</p>
                  <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes6`) }}</p>
                </span>
              </v-col>
              <v-col cols="12" class="pa-0 d-flex align-center justify-center">
                <span class="white--text text-center">
                  <p class="descriptionVipText ">{{ $t(`label.beVipDes6a`) }}</p>
                  <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes7`) }}</p>
                </span>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-img>
      <div class="mt-2 pb-3 vip-tier-mobile">
        <!--        <v-img class="align-center" :aspect-ratio="1800 / 650" :src="`${mediaUrl}/game_banner/be_vip_desktop.jpg`">-->
        <v-row no-gutters>
          <v-img class="" :src="`${mediaUrl}/game_banner/be_vip_girl.png`">
            <v-row no-gutters style="height: 100%">
              <v-col cols="5"></v-col>
              <v-col cols="7" class="d-flex align-center justify-center">
                <v-col cols="9" class="pa-0">
                  <div class="be-vip white--text">
                    <h3 class="headertext">{{ $t(`label.beVip`) }}</h3>
                    <p class="descriptionVipText">{{ $t(`label.beVipDes1`) }}</p>
                    <p class="descriptionVipText">{{ $t(`label.beVipDes2`) }}</p>
                    <p class="descriptionVipText">{{ $t(`label.beVipDes3`) }}</p>
                    <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes5`) }}</p>
                    <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes6`) }}</p>
                    <p class="descriptionVipText ">{{ $t(`label.beVipDes6a`) }}</p>
                    <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes7`) }}</p>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-img>
        </v-row>
        <!--        <v-row no-gutters class="be-vip-container">-->
        <!--          <v-col cols="5">-->
        <!--            <v-img class="align-center vipGirlImg" height="450px" :src="`${mediaUrl}/game_banner/be_vip_girl.jpg`"></v-img>-->
        <!--          </v-col>-->
        <!--          <v-col cols="7" class="d-flex align-center justify-center">-->
        <!--            <div class="be-vip white&#45;&#45;text pa-6">-->
        <!--              <h3 class="headertext">{{ $t(`label.beVip`) }}</h3>-->
        <!--              <p class="descriptionVipText">{{ $t(`label.beVipDes1`) }}</p>-->
        <!--              <p class="descriptionVipText">{{ $t(`label.beVipDes2`) }}</p>-->
        <!--              <p class="descriptionVipText">{{ $t(`label.beVipDes3`) }}</p>-->
        <!--              <p class="descriptionText mb-0">{{ $t(`label.beVipDes5`) }}</p>-->
        <!--              <p class="descriptionText mb-0">{{ $t(`label.beVipDes6`) }}</p>-->
        <!--              <p class="descriptionText ">{{ $t(`label.beVipDes6a`) }}</p>-->
        <!--              <p class="descriptionText mb-0">{{ $t(`label.beVipDes7`) }}</p>-->
        <!--            </div>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </div>
      <v-row no-gutters>
        <v-col cols="12" class="MB-right-col d-flex align-center py-3">
          <v-row class="justify-center align-center" no-gutters>
            <v-col cols="1" class="pa-0">
              <v-btn @click="showPreviousLevel" class="btnleft" style="min-width: 10px;">
                <
              </v-btn>
            </v-col>

            <v-col cols="1" class="pa-0 d-flex mr-2 flex-column align-center">
              <v-row no-gutters class="align-items-center justify-center tierpngHalf">
                <v-avatar tile class="tier-nextPass" width="100%" height="100%">
                  <v-img class="" :src="`${getImageSrcHalfRight('past2')}`" eager></v-img>
                </v-avatar>
              </v-row>
              <v-row no-gutters class="justify-center pt-4" v-if="getImageSrc('past2') !== ''">
                <span class="nameCoverETC">{{ truncatedItemName($t(`label.${getImageSrc('past2')}`)) }}</span>
              </v-row>
            </v-col>
            <v-col cols="2" class="pa-0 d-flex flex-column align-center">
              <v-row no-gutters class="align-items-center justify-center tierpng">
                <v-avatar tile class="" width="100%" height="100%">
                  <v-img class="otherTierImage" :src="`/static/image/viptier/${getImageSrc('past')}_effect.png`" eager></v-img>
                </v-avatar>
              </v-row>
              <v-row no-gutters class="justify-center pt-4" v-if="getImageSrc('past') !== ''">
                <span class="nameCoverETC ml-1">{{ $t(`label.${getImageSrc('past')}`) }}</span>
              </v-row>
            </v-col>
            <v-col cols="3" class="py-0 d-flex flex-column align-center">
              <v-row no-gutters class="align-items-center justify-center tierpng">
                <v-avatar tile class="" width="100%" height="100%" style="transform: scale(1.2);">
                  <v-img :src="`/static/image/viptier/${getTextColor()}_effect.png`" eager></v-img>
                </v-avatar>
              </v-row>
              <v-row no-gutters class="justify-center pt-4">
                <span class="nameCover">{{ $t(`label.${getTextColor()}`) }}</span>
              </v-row>
            </v-col>
            <v-col cols="2" class="pa-0 d-flex flex-column align-center">
              <v-row no-gutters class="align-items-center justify-center tierpng">
                <v-avatar tile class="" width="100%" height="100%">
                  <v-img class="otherTierImage" :src="`/static/image/viptier/${getImageSrc('next')}_effect.png`" eager></v-img>
                </v-avatar>
              </v-row>
              <v-row no-gutters class="justify-center pt-4" v-if="getImageSrc('next') !== ''">
                <span class="nameCoverETC mr-1">{{ $t(`label.${getImageSrc('next')}`) }}</span>
              </v-row>
            </v-col>
            <v-col cols="1" class="pa-0 ml-2 d-flex flex-column align-center">
              <v-row no-gutters class="align-items-center justify-center tierpngHalf">
                <v-avatar tile class="tier-nextPass" width="100%" height="100%">
                  <v-img class="" :src="`${getImageSrcHalfLeft('next2')}`" eager></v-img>
                </v-avatar>
              </v-row>
              <v-row no-gutters class="justify-center pt-4" v-if="getImageSrc('next2') !== ''">
                <span class="nameCoverETC">{{ truncatedItemName($t(`label.${getImageSrc('next2')}`)) }}</span>
              </v-row>
            </v-col>

            <v-col cols="1" class="pa-0">
              <v-btn @click="showNextLevel" class="btnright" style="min-width: 10px;">
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row no-gutters class="row-border">
            <v-col cols="12" class="col-padding">
              <span class="headerTable white--text">{{ $t(`label.vipReqBenefit`) }}</span>
            </v-col>
          </v-row>
          <div class="coveredRow">
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="col-padding">
                <span class="headerTable white--text">{{ $t(`label.vipUpgrade`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText"></v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.monthlyDepositRequirement`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <!--                  tierSettingsDetail[tierTab.key].depositUpgradeRequirement-->
                <span class="white--text">{{ currentTierDetails.monthlyDepositRequirement }}</span>
              </v-col>
            </v-row>
          </div>
          <div class="uncoveredRow">
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="col-padding">
                <span class="headerTable white--text">{{ $t(`label.vipBenefits`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText"></v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.upgradeBonus`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <!--                  tierSettingsDetail[tierTab.key].depositUpgradeRequirement-->
                <span class="white--text">{{ formatCurrency(currentTierDetails.vipUpgradeBonus) }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.depositRewardCoin`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <!--                  tierSettingsDetail[tierTab.key].depositUpgradeRequirement-->
                <span class="white--text">{{ currentTierDetails.depositRewardCoins }}%</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.birthdayBonus`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <!--                  tierSettingsDetail[tierTab.key].depositUpgradeRequirement-->
                <span class="white--text">{{ formatCurrency(currentTierDetails.birthdayBonus) }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.specialGift`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <img v-if="currentTierDetails.specialGift === 1" src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
              </v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.vipEvents`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <img v-if="currentTierDetails.vipEvent === 1" src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
              </v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.exclusiveAccess`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <img v-if="currentTierDetails.exclusiveAccess === 1" src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
              </v-col>
            </v-row>

            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.dedicatedVipManager`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <img v-if="currentTierDetails.dedicatedVipManager === 1" src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
              </v-col>
            </v-row>
            <!--            <v-row no-gutters class="row-border">-->
            <!--              <v-col cols="8" class="tableText col-border">-->
            <!--                <span class="white&#45;&#45;text">{{ $t(`label.weeklyBonus`) }}</span>-->
            <!--              </v-col>-->
            <!--              <v-col cols="4" class="col-padding border_left tableText">-->
            <!--                &lt;!&ndash;                  tierSettingsDetail[tierTab.key].depositUpgradeRequirement&ndash;&gt;-->
            <!--                <span class="white&#45;&#45;text">{{ currentTierDetails.weeklyCashBack }}%</span>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <!--            <v-row no-gutters class="row-border">-->
            <!--              <v-col cols="8" class="tableText col-border">-->
            <!--                <span class="white&#45;&#45;text">{{ $t(`label.weeklyBonusMax`) }}</span>-->
            <!--              </v-col>-->
            <!--              <v-col cols="4" class="col-padding border_left tableText">-->
            <!--                &lt;!&ndash;                  tierSettingsDetail[tierTab.key].depositUpgradeRequirement&ndash;&gt;-->
            <!--                <span class="white&#45;&#45;text">{{ formatCurrency(currentTierDetails.weeklyCashBackMax) }}</span>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </div>
          <div class="coveredRow">
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="col-padding">
                <span class="headerTable white--text">{{ $t(`label.retentionRequirement`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText"></v-col>
            </v-row>
            <v-row no-gutters class="row-border">
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.monthlyRetention`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <span class="white--text">{{ formatCurrency(currentTierDetails.monthlyRentention) }}</span>
              </v-col>
            </v-row>
            <!--            <v-row no-gutters class="row-border">-->
            <!--              <v-col cols="8" class="col-padding">-->
            <!--                <span class="headerTable white&#45;&#45;text">{{ $t(`label.otherPerks`) }}</span>-->
            <!--              </v-col>-->
            <!--              <v-col cols="4" class="col-padding border_left tableText"></v-col>-->
            <!--            </v-row>-->
            <!--            <v-row no-gutters class="row-border">-->
            <!--              <v-col cols="8" class="tableText col-border">-->
            <!--                <span class="white&#45;&#45;text">{{ $t(`label.depositWithdrawPriority`) }}</span>-->
            <!--              </v-col>-->
            <!--              <v-col cols="4" class="col-padding border_left tableText">-->
            <!--                <img v-if="currentTierDetails.depositWithdrawalPrior === 1" src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </div>
          <div class="uncoveredRow">
            <v-row no-gutters>
              <v-col cols="8" class="tableText col-border">
                <span class="white--text">{{ $t(`label.memberRenewal`) }}</span>
              </v-col>
              <v-col cols="4" class="col-padding border_left tableText">
                <span class="white--text">{{ $t(`label.${currentTierDetails.membershipRenewal}`) }}</span>
              </v-col>
            </v-row>
          </div>
          <v-row no-gutters class="d-flex justify-center align-center mt-5">
            <v-col cols="5" class="pa-0 justify-center align-center">
              <v-btn height="auto" width="100%" class="text-uppercase pa-3 findMoreButton" color="#ffce01" @click="goToPromotionVipTier()">{{ $t(`label.findOutMore`) }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="coveredRow pa-4 mt-3 d-block">
        <p class="white--text tncFaqTitle pp mb-2 font-weight-bold">{{ $t(`label.beVipDes3`) }}</p>
        <p class="white--text mb-2 pp">{{ $t(`label.beVipDes3`) }}</p>
        <p class="white--text mb-2 pp">{{ $t(`label.beVipDes5`) }}</p>
        <p class="white--text pp">{{ $t(`label.beVipDes6`) }}</p>
        <p class="white--text mb-2 pp">{{ $t(`label.beVipDes6a`) }}</p>
        <p class="white--text pp">{{ $t(`label.beVipDes7`) }}</p>
      </v-row>
      <v-row no-gutters class="coveredRow pa-4 mt-3">
        <div class="faq-tnc-container">
          <v-btn class="tncbtn" width="100%" style="text-transform: none !important;" @click="toggleTnc">
            <span class="faqspan tncFaqTitle font-weight-bold">{{ $t(`label.faqVipTitle`) }}</span>
            <div :class="['triangle', showTnc ? 'down' : 'up']"></div>
          </v-btn>
          <v-expand-transition>
            <div v-if="showTnc" class="px-4 pb-4 pt-2">
              <p class="pp black--text font-weight-bold pb-2 tncFaqTitle">{{ $t(`label.faqViptier1a`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier1b`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier1c`) }}</p>
              <p class="pp black--text pb-2">{{ $t(`label.faqViptier1d`) }}</p>
              <p class="pp black--text font-weight-bold pb-2 tncFaqTitle">{{ $t(`label.faqViptier2a`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier2b`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier2c`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier2d`) }}</p>
              <p class="pp black--text pb-2">{{ $t(`label.faqViptier2e`) }}</p>
              <p class="pp black--text font-weight-bold pb-2 tncFaqTitle">{{ $t(`label.faqViptier3a`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier3b`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier3c`) }}</p>
              <p class="pp black--text pb-2">{{ $t(`label.faqViptier3d`) }}</p>
              <p class="pp black--text font-weight-bold pb-2 tncFaqTitle">{{ $t(`label.faqViptier4a`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier4b`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier4c`) }}</p>
              <p class="pp black--text pb-2">{{ $t(`label.faqViptier4d`) }}</p>
              <p class="pp black--text font-weight-bold pb-2 tncFaqTitle">{{ $t(`label.faqViptier5a`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier5b`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier5c`) }}</p>
              <p class="pp black--text">{{ $t(`label.faqViptier5d`) }}</p>
            </div>
          </v-expand-transition>
        </div>
        <div class="faq-tnc-container mt-3">
          <v-btn class="faqbtn" width="100%" style="text-transform: none !important;" @click="toggleFaq">
            <span class="faqspan tncFaqTitle font-weight-bold">{{ $t(`label.tncViptitle`) }}</span>
            <div :class="['triangle', showFaq ? 'down' : 'up']"></div>
          </v-btn>
          <v-expand-transition>
            <div v-if="showFaq" class="px-4 pb-4 pt-2">
              <p class="pp black--text pb-2">{{ $t(`label.tncViptierA`) }}</p>
              <p class="pp black--text pb-2">{{ $t(`label.tncViptierB`) }}</p>
              <p class="pp black--text pb-2">{{ $t(`label.tncViptierC`) }}</p>
            </div>
          </v-expand-transition>
        </div>
      </v-row>
    </div>

    <div class="hidden-md-and-down">
      <v-img class="align-center" :aspect-ratio="1800 / 450" :src="`${mediaUrl}/game_banner/tier_background_desktop.jpg`">
        <v-row style="padding: 1% 12%" no-gutters class="d-flex justify-center">
          <span class="white--text joinVipText">{{ $t(`label.minDepoMonth`) }}</span>
        </v-row>
        <v-row style="padding: 1% 12%" no-gutters class="d-flex justify-space-around">
          <div v-for="(image, index) in imagesVip" :key="index" class="level-col">
            <v-row no-gutters>
              <v-col cols="12" class="pa-0 ma-0">
                <div class="d-flex flex-column justify-center align-center">
                  <v-row no-gutters :style="{ 'max-width': getMaxWidth(index) + 'px' }">
                    <v-img :src="image.src"></v-img>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-row style="padding: 1% 12%" no-gutters class="d-flex justify-space-around">
          <div v-for="(image, index) in imagesVip" :key="index" class="level-col">
            <v-row no-gutters>
              <v-col cols="12" class="pa-0 ma-0">
                <div class="d-flex flex-column justify-center align-center" :style="{ width: getMaxWidth(index) + 'px' }">
                  <v-row no-gutters>
                    <span class="white--text">{{ $t(`label.${image.name.toLowerCase()}`) }}</span>
                  </v-row>
                  <v-row no-gutters>
                    <span class="white--text">{{ image.amount }}</span>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-img>
      <div style="background: #1E1E1E;">
        <div style="padding: 1% 12%">
          <v-row no-gutters>
            <v-img class="" :src="`${mediaUrl}/game_banner/be_vip_girl.png`">
              <v-row no-gutters style="height: 100%">
                <v-col cols="5"></v-col>
                <v-col cols="7" class="d-flex align-center justify-center">
                  <v-col cols="9" class="pa-0">
                    <div class="be-vip white--text">
                      <h3 class="headertext">{{ $t(`label.beVip`) }}</h3>
                      <p class="descriptionVipText">{{ $t(`label.beVipDes1`) }}</p>
                      <p class="descriptionVipText">{{ $t(`label.beVipDes2`) }}</p>
                      <p class="descriptionVipText">{{ $t(`label.beVipDes3`) }}</p>
                      <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes5`) }}</p>
                      <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes6`) }}</p>
                      <p class="descriptionVipText ">{{ $t(`label.beVipDes6a`) }}</p>
                      <p class="descriptionVipText mb-0">{{ $t(`label.beVipDes7`) }}</p>
                    </div>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </v-row>
        </div>
        <div class="vip-tierdesktop">
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0"></v-col>
            <v-col cols="9" class="cols-border d-flex text-center pa-0 ">
              <v-col class="border_left pb-5 pa-0" v-for="(name, index) in tierImages" :key="index">
                <p class="righttextdesktop ma-0"><v-img :src="name.src" width="45" height="45"></v-img></p>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0"></v-col>
            <v-col cols="9" class="d-flex text-center pa-0 ">
              <v-col class="border_left pb-5 pa-0" v-for="(name, index) in levelNames" :key="index">
                <p class="righttextdesktop ma-0 level-Names">{{ name }}</p>
              </v-col>
            </v-col>
          </v-row>
          <div class="vipTable_bg">
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <h1 class="lefttextdesktop font-weight-bold pl-6 category-Title">{{ $t(`label.vipUpgrade`) }}</h1>
              </v-col>
              <v-col cols="9" class="d-flex text-center pa-0 ">
                <v-col class="border_left pa-0" v-for="(empty, index) in empties" :key="index">
                  <p class="righttextdesktop ma-0">{{ empty }}</p>
                </v-col>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <p class="lefttextdesktop pl-6">{{ $t(`label.monthlyDepositRequirement`) }}</p>
              </v-col>
              <v-col cols="9" class="d-flex text-center pa-0">
                <v-col class="border_left pa-0" v-for="(req, index) in oneMonthDeposits" :key="index">
                  <p class="righttextdesktop ma-0">{{ req }}</p>
                </v-col>
              </v-col>
            </v-row>
            <!--          <v-row class="ma-0">-->
            <!--            <v-col cols="3" class="pa-0">-->
            <!--              <p class="lefttextdesktop pl-6">{{ $t(`label.threeMonthRequirement`) }}</p>-->
            <!--            </v-col>-->
            <!--            <v-col cols="9" class="d-flex text-center pa-0">-->
            <!--              <v-col class="border_left pa-0" v-for="req in threeMonthDeposits" :key="req">-->
            <!--                <p class="righttextdesktop ma-0">{{ req }}</p>-->
            <!--              </v-col>-->
            <!--            </v-col>-->
            <!--          </v-row>-->
          </div>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop font-weight-bold pl-6 category-Title">{{ $t(`label.vipBenefits`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0 ">
              <v-col class="border_left pa-0" v-for="(empty, index) in empties" :key="index">
                <p class="righttextdesktop ma-0">{{ empty }}</p>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.upgradeBonus`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <v-col class="border_left pa-0" v-for="(bonus, index) in upgradeBonuses" :key="index">
                <p class="righttextdesktop ma-0">{{ formatCurrency(bonus) }}</p>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.depositRewardCoin`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <v-col class="border_left pa-0" v-for="(bonus, index) in depositRewardCoins" :key="index">
                <p class="righttextdesktop ma-0">{{ bonus }}</p>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.birthdayBonus`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <v-col class="border_left pa-0" v-for="(bonus, index) in bdBonuses" :key="index">
                <p class="righttextdesktop ma-0">{{ formatCurrency(bonus) }}</p>
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.specialGift`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <template v-for="(star, index) in specialGift">
                <v-col v-if="star === 1" class="border_left pa-0" :key="index">
                  <!-- Render the PNG if the value is 1 -->
                  <img src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
                </v-col>
                <v-col v-else class="border_left pa-0" :key="index">
                  <!-- Render the percentage if the value is not 1 -->
                  <p class="righttextdesktop ma-0"></p>
                </v-col>
              </template>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.vipEvents`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <template v-for="(star, index) in vipEvents">
                <v-col v-if="star === 1" class="border_left pa-0" :key="index">
                  <!-- Render the PNG if the value is 1 -->
                  <img src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
                </v-col>
                <v-col v-else class="border_left pa-0" :key="index">
                  <!-- Render the percentage if the value is not 1 -->
                  <p class="righttextdesktop ma-0"></p>
                </v-col>
              </template>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.exclusiveAccess`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <template v-for="(star, index) in exclusiveAccess">
                <v-col v-if="star === 1" class="border_left pa-0" :key="index">
                  <!-- Render the PNG if the value is 1 -->
                  <img src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
                </v-col>
                <v-col v-else class="border_left pa-0" :key="index">
                  <!-- Render the percentage if the value is not 1 -->
                  <p class="righttextdesktop ma-0"></p>
                </v-col>
              </template>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3" class="pa-0">
              <p class="lefttextdesktop pl-6">{{ $t(`label.dedicatedVipManager`) }}</p>
            </v-col>
            <v-col cols="9" class="d-flex text-center pa-0">
              <template v-for="(star, index) in dedicatedVipManager">
                <v-col v-if="star === 1" class="border_left pa-0" :key="index">
                  <!-- Render the PNG if the value is 1 -->
                  <img src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />
                </v-col>
                <v-col v-else class="border_left pa-0" :key="index">
                  <!-- Render the percentage if the value is not 1 -->
                  <p class="righttextdesktop ma-0"></p>
                </v-col>
              </template>
            </v-col>
          </v-row>
          <!--          <v-row class="ma-0">-->
          <!--            <v-col cols="3" class="pa-0">-->
          <!--              <p class="lefttextdesktop pl-6">{{ $t(`label.weeklyBonus`) }}</p>-->
          <!--            </v-col>-->
          <!--            <v-col cols="9" class="d-flex text-center pa-0">-->
          <!--              <v-col class="border_left pa-0" v-for="(bonus, index) in weeklyBonuses" :key="index">-->
          <!--                <p class="righttextdesktop ma-0">{{ bonus }}%</p>-->
          <!--              </v-col>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--          <v-row class="ma-0">-->
          <!--            <v-col cols="3" class="pa-0">-->
          <!--              <p class="lefttextdesktop pl-6">{{ $t(`label.weeklyBonusMax`) }}</p>-->
          <!--            </v-col>-->
          <!--            <v-col cols="9" class="d-flex text-center pa-0">-->
          <!--              <v-col class="border_left pa-0" v-for="(bonus, index) in weeklyBonusesMax" :key="index">-->
          <!--                <p class="righttextdesktop ma-0">{{ formatCurrency(bonus) }}</p>-->
          <!--              </v-col>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--        <div class="vipTable_bg">-->
          <!--          <v-row class="ma-0">-->
          <!--            <v-col cols="3" class="pa-0">-->
          <!--              <p class="lefttextdesktop font-weight-bold pl-6">{{ $t(`label.specialBonus`) }}</p>-->
          <!--            </v-col>-->
          <!--            <v-col cols="9" class="d-flex text-center pa-0 ">-->
          <!--              <v-col class="border_left pa-0" v-for="(empty, index) in empties" :key="index">-->
          <!--                <p class="righttextdesktop ma-0">{{ empty }}</p>-->
          <!--              </v-col>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--          <v-row class="ma-0">-->
          <!--            <v-col cols="3" class="pa-0">-->
          <!--              <p class="lefttextdesktop pl-6">{{ $t(`label.slotPercentages`) }}</p>-->
          <!--            </v-col>-->
          <!--            <v-col cols="9" class="d-flex text-center pa-0">-->
          <!--              <v-col class="border_left pa-0" v-for="(percentages, index) in slotPercentages" :key="index">-->
          <!--                <p class="righttextdesktop ma-0">{{ percentages }}</p>-->
          <!--              </v-col>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--          <v-row class="ma-0">-->
          <!--            <v-col cols="3" class="pa-0">-->
          <!--              <p class="lefttextdesktop pl-6">{{ $t(`label.otherPercentages`) }}</p>-->
          <!--            </v-col>-->
          <!--            <v-col cols="9" class="d-flex text-center pa-0">-->
          <!--              <v-col class="border_left pa-0" v-for="(percentages, index) in otherPercentages" :key="index">-->
          <!--                <p class="righttextdesktop ma-0">{{ percentages }}</p>-->
          <!--              </v-col>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--        </div>-->
          <div class="vipTable_bg">
            <!--            <v-row class="ma-0">-->
            <!--              <v-col cols="3" class="pa-0">-->
            <!--                <p class="lefttextdesktop font-weight-bold pl-6 category-Title">{{ $t(`label.otherPerks`) }}</p>-->
            <!--              </v-col>-->
            <!--              <v-col cols="9" class="d-flex text-center pa-0 ">-->
            <!--                <v-col class="border_left pa-0" v-for="(empty, index) in empties" :key="index">-->
            <!--                  <p class="righttextdesktop ma-0">{{ empty }}</p>-->
            <!--                </v-col>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <!--            <v-row class="ma-0">-->
            <!--              <v-col cols="3" class="pa-0">-->
            <!--                <p class="lefttextdesktop pl-6">{{ $t(`label.depositWithdrawPriority`) }}</p>-->
            <!--              </v-col>-->
            <!--              <v-col cols="9" class="d-flex text-center pa-0">-->
            <!--                <template v-for="(star, index) in depositWithdrawPriority">-->
            <!--                  <v-col v-if="star === 1" class="border_left pa-0" :key="index">-->
            <!--                    &lt;!&ndash; Render the PNG if the value is 1 &ndash;&gt;-->
            <!--                    <img src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />-->
            <!--                  </v-col>-->
            <!--                  <v-col v-else class="border_left pa-0" :key="index">-->
            <!--                    &lt;!&ndash; Render the percentage if the value is not 1 &ndash;&gt;-->
            <!--                    <p class="righttextdesktop ma-0"></p>-->
            <!--                  </v-col>-->
            <!--                </template>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <p class="lefttextdesktop font-weight-bold pl-6 category-Title">{{ $t(`label.retentionRequirement`) }}</p>
              </v-col>
              <v-col cols="9" class="d-flex text-center pa-0 ">
                <v-col class="border_left pa-0" v-for="(empty, index) in empties" :key="index">
                  <p class="righttextdesktop ma-0">{{ empty }}</p>
                </v-col>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <p class="lefttextdesktop pl-6">{{ $t(`label.threeMonthDepositReq`) }}</p>
              </v-col>
              <v-col cols="9" class="d-flex text-center pa-0">
                <v-col class="border_left pa-0" v-for="(req, index) in threeMonthDepositReqs" :key="index">
                  <p class="righttextdesktop ma-0">{{ formatCurrency(req) }}</p>
                </v-col>
              </v-col>
            </v-row>
            <!--        <v-row class="ma-0">-->
            <!--          <v-col cols="3" class="pa-0">-->
            <!--            <p class="lefttextdesktop pl-6">{{ $t(`label.depositWithdrawOption`) }}</p>-->
            <!--          </v-col>-->
            <!--          <v-col cols="9" class="d-flex text-center pa-0">-->
            <!--            <template v-for="(star, index) in depositWithdrawOption">-->
            <!--              <v-col v-if="star === 1" class="border_left pa-0" :key="index">-->
            <!--                &lt;!&ndash; Render the PNG if the value is 1 &ndash;&gt;-->
            <!--                <img src="/static/image/viptier/goldStar.png" alt="gold star" style="max-width: 100%; max-height: 100%;" />-->
            <!--              </v-col>-->
            <!--              <v-col v-else class="border_left pa-0" :key="index">-->
            <!--                &lt;!&ndash; Render the percentage if the value is not 1 &ndash;&gt;-->
            <!--                <p class="righttextdesktop ma-0"></p>-->
            <!--              </v-col>-->
            <!--            </template>-->
            <!--          </v-col>-->
            <!--        </v-row>-->
          </div>

          <!--          <v-row class="ma-0">-->
          <!--            <v-col cols="3" class="pa-0">-->
          <!--              <p class="lefttextdesktop pl-6">{{ $t(`label.threeMonthToReq`) }}</p>-->
          <!--            </v-col>-->
          <!--            <v-col cols="9" class="d-flex text-center pa-0">-->
          <!--              <v-col class="border_left pa-0" v-for="(req, index) in threeMonthToReq" :key="index">-->
          <!--                <p class="righttextdesktop ma-0">{{ req }}</p>-->
          <!--              </v-col>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <div class="">
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <p class="lefttextdesktop pl-6 category-Title">{{ $t(`label.memberRenewal`) }}</p>
              </v-col>
              <v-col cols="9" class="d-flex text-center pa-0">
                <v-col class="border_left pa-0" v-for="(renewal, index) in memberRenewal" :key="index">
                  <p class="righttextdesktop ma-0">{{ $t(`label.${renewal.toLowerCase()}`) }}</p>
                </v-col>
              </v-col>
            </v-row>
            <v-row no-gutters class="d-flex justify-center align-center mt-5">
              <v-col cols="2" class="pa-0 justify-center align-center">
                <v-btn height="auto" width="100%" class="text-uppercase pa-3 findMoreButton" color="#ffce01" @click="goToPromotionVipTier()">{{ $t(`label.findOutMore`) }}</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-img class="" style="" :aspect-ratio="1600 / 350" :src="`${mediaUrl}/game_banner/vipTierBackground.jpg`">
          <v-row no-gutters class="d-flex justify-space-around py-16" style="position: relative; padding: 2% 12% !important;">
            <v-col cols="6" class="tncFaqCol pa-5">
              <h2 style="font-size: 18px;display: flex; padding-bottom: 20px; color: #fff">{{ $t(`label.faqVipTitle`) }}</h2>
              <p class="pp pb-2 font-weight-bold">{{ $t(`label.faqViptier1a`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier1b`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier1c`) }}</p>
              <p class="pp pb-2">{{ $t(`label.faqViptier1d`) }}</p>
              <p class="pp pb-2 font-weight-bold">{{ $t(`label.faqViptier2a`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier2b`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier2c`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier2d`) }}</p>
              <p class="pp pb-2">{{ $t(`label.faqViptier2e`) }}</p>
              <p class="pp pb-2 font-weight-bold">{{ $t(`label.faqViptier3a`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier3b`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier3c`) }}</p>
              <p class="pp pb-2">{{ $t(`label.faqViptier3d`) }}</p>
              <p class="pp pb-2">{{ $t(`label.faqViptier4a`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier4b`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier4c`) }}</p>
              <p class="pp pb-2">{{ $t(`label.faqViptier4d`) }}</p>
              <p class="pp pb-2">{{ $t(`label.faqViptier5a`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier5b`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier5c`) }}</p>
              <p class="pp">{{ $t(`label.faqViptier5d`) }}</p>
            </v-col>
            <v-col cols="6" class="tncFaqCol pa-5 white--text">
              <v-row no-gutters style="width: 80%;">
                <h2 style="font-size: 18px;display: flex; padding-bottom: 20px;">{{ $t(`label.tncViptitle`) }}</h2>
                <p class="pp pb-2">{{ $t(`label.tncViptierA`) }}</p>
                <p class="pp pb-5">{{ $t(`label.tncViptierB`) }}</p>
                <p class="pp">{{ $t(`label.tncViptierC`) }}</p>
              </v-row>
            </v-col>
            <img src="/static/image/viptier/Sexy-Girl.png" class="cashGirl" alt="Your Image" />
          </v-row>
        </v-img>
      </div>
    </div>
    <!--    <div class="hidden-lg-and-up"></div>-->
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_OVERVIEW, REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION, REFERRAL_GET_CONFIG } from '@/store/referral.module'
import stringFormat from 'string-format'
import currencySymbolMapp from 'currency-symbol-map'
import { MEMBER_WALLET, MEMBER_VIP_TIER_PROGRESS } from '@/store/member.module'
import AppMemberMenuBar from '@/components/layout/MemberMenuBar.vue'
export default {
  name: 'viptier',
  components: {
    AppMemberMenuBar,
    VueQrCode,
    AppLoginEmbedded
  },
  data: () => ({
    wordLimit: 3,
    currentIndex: 0,
    isFirstTier: false,
    isLastTier: false,
    currentTierIndex: 0,
    vipTierTabs: {
      newmember: 'Member',
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
      emerald: 'Emerald',
      sapphire: 'Sapphire',
      platinum: 'Platinum',
      diamond: 'Diamond'
    },
    tierSettingsDetail: {
      newmember: {
        monthlyDepositRequirement: '',
        vipUpgradeBonus: '',
        depositRewardCoins: '1',
        birthdayBonus: '',
        weeklyCashBack: '',
        weeklyCashBackMax: '',
        depositWithdrawalPrior: 0,
        specialGift: 0,
        vipEvent: 0,
        dedicatedVipManager: 0,
        exclusiveAccess: 0,
        monthlyRentention: '',
        membershipRenewal: 'lifetime'
      },
      bronze: {
        monthlyDepositRequirement: '৳60,000',
        vipUpgradeBonus: '688',
        depositRewardCoins: '1',
        birthdayBonus: '688',
        weeklyCashBack: '8.88',
        weeklyCashBackMax: '688',
        depositWithdrawalPrior: 0,
        specialGift: 0,
        vipEvent: 0,
        dedicatedVipManager: 0,
        exclusiveAccess: 0,
        monthlyRentention: '0',
        membershipRenewal: 'lifetime'
      },
      silver: {
        monthlyDepositRequirement: '৳125,000',
        vipUpgradeBonus: '2,288',
        depositRewardCoins: '1.2',
        birthdayBonus: '1,888',
        weeklyCashBack: '8.88',
        weeklyCashBackMax: '1,888',
        depositWithdrawalPrior: 0,
        specialGift: 1,
        vipEvent: 0,
        dedicatedVipManager: 0,
        exclusiveAccess: 0,
        monthlyRentention: '62,500',
        membershipRenewal: 'monthly'
      },
      gold: {
        monthlyDepositRequirement: '৳300,000',
        vipUpgradeBonus: '5,888',
        depositRewardCoins: '1.5',
        birthdayBonus: '3,888',
        weeklyCashBack: '8.88',
        weeklyCashBackMax: '3,888',
        depositWithdrawalPrior: 1,
        specialGift: 1,
        vipEvent: 1,
        dedicatedVipManager: 0,
        exclusiveAccess: 0,
        monthlyRentention: '150,000',
        membershipRenewal: 'monthly'
      },
      emerald: {
        monthlyDepositRequirement: '৳500,000',
        vipUpgradeBonus: '8,888',
        depositRewardCoins: '1.5',
        birthdayBonus: '6,888',
        weeklyCashBack: '10.88',
        weeklyCashBackMax: '8,888',
        depositWithdrawalPrior: 1,
        specialGift: 1,
        vipEvent: 1,
        dedicatedVipManager: 0,
        exclusiveAccess: 0,
        monthlyRentention: '250,000',
        membershipRenewal: 'monthly'
      },
      sapphire: {
        monthlyDepositRequirement: '৳1,000,000',
        vipUpgradeBonus: '18,888',
        depositRewardCoins: '2',
        birthdayBonus: '12,888',
        weeklyCashBack: '10.88',
        weeklyCashBackMax: '18,888',
        depositWithdrawalPrior: 1,
        specialGift: 1,
        vipEvent: 1,
        dedicatedVipManager: 0,
        exclusiveAccess: 1,
        monthlyRentention: '500,000',
        membershipRenewal: 'monthly'
      },
      platinum: {
        monthlyDepositRequirement: '৳3,000,000',
        vipUpgradeBonus: '32,888',
        depositRewardCoins: '2',
        birthdayBonus: '18,888',
        weeklyCashBack: '11.88',
        weeklyCashBackMax: '38,888',
        depositWithdrawalPrior: 1,
        specialGift: 1,
        vipEvent: 1,
        dedicatedVipManager: 1,
        exclusiveAccess: 1,
        monthlyRentention: '1,500,000',
        membershipRenewal: 'monthly'
      },
      diamond: {
        monthlyDepositRequirement: locale.getMessage('label.invitedonly'),
        vipUpgradeBonus: '38,888',
        depositRewardCoins: '2',
        birthdayBonus: '26,888',
        weeklyCashBack: '11.88',
        weeklyCashBackMax: '88,888',
        depositWithdrawalPrior: 1,
        specialGift: 1,
        vipEvent: 1,
        dedicatedVipManager: 1,
        exclusiveAccess: 1,
        monthlyRentention: '3,000,000',
        depositUpgradeRequirement: locale.getMessage('label.specialInvitation'),
        turnoverUpgradeRequirement: locale.getMessage('label.specialInvitation'),
        monthlyDepositRequired: locale.getMessage('label.specialInvitation'),
        monthlyTurnoverRequired: locale.getMessage('label.specialInvitation'),
        membershipRenewal: 'invitedonly'
      }
    },
    tab: null,
    viptiers: ['Bronze', 'Silver', 'Gold', 'Sapphire', 'Diamond', 'Platinum', 'Emerald'], // Array of rank names
    upgradeRequirement: ['Monthly Deposit Requirement', 'OR 3 Month accumulated'], // Array of label names
    stars: [1, 2, 3, 4, 5, 6, 7], // Array of star levels
    starRequirements: [10, 20, 30, 40, 50, 60, 70], // Array of star requirements (adjust as needed)
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    showFaq: false,
    showTnc: false,
    dateOfBirthMenu: false,
    stringFormat: stringFormat,
    currencySymbolMapp: currencySymbolMapp,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    overview: {},
    redeemCommissionAmt: 0.0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    images: [
      { id: 1, src: '/static/image/viptier/bronze.png', name: 'Bronze', amount: '৳60,000' },
      { id: 2, src: '/static/image/viptier/gold.png', name: 'Gold', amount: '৳300,000' },
      { id: 3, src: '/static/image/viptier/sapphire.png', name: 'Sapphire', amount: '৳1,000,000' },
      { id: 4, src: '/static/image/viptier/diamond.png', name: 'Diamond', amount: '৳5,000,000' },
      { id: 5, src: '/static/image/viptier/platinum.png', name: 'Platinum', amount: '৳3,000,000' },
      { id: 6, src: '/static/image/viptier/emerald.png', name: 'Emerald', amount: '৳500,000' },
      { id: 7, src: '/static/image/viptier/silver.png', name: 'Silver', amount: '৳125,000' }
    ],
    tiers: [
      { name: 'club', color: 'bronze', progress: 16.67 },
      { name: 'diamond', color: 'silver', progress: 33.33 },
      { name: 'heart', color: 'gold', progress: 50 },
      { name: 'spade', color: 'platinum', progress: 66.67 },
      { name: 'ace', color: 'diamond', progress: 83.33 },
      { name: 'raja', color: 'black', progress: 100 }
    ],
    notLoading: true,
    routeName: ROUTE_NAME,
    showProgress: false,
    tierBarBgColor: '',
    tierBarColor: '',
    tierColorThemeName: 'club',
    progressLevels: ['club', 'diamond', 'heart', 'spade', 'ace', 'raja'],
    currentProgress: 0,
    DesktopcurrentProgress: 0,
    tierName: '',
    currentProgressPercentage: 0,
    currentLv: 0,
    level: 1,
    tierImages: [
      { id: 1, src: '/static/image/viptier/newmember.png' },
      { id: 2, src: '/static/image/viptier/bronze.png' },
      { id: 3, src: '/static/image/viptier/silver.png' },
      { id: 4, src: '/static/image/viptier/gold.png' },
      { id: 5, src: '/static/image/viptier/emerald.png' },
      { id: 6, src: '/static/image/viptier/sapphire.png' },
      { id: 7, src: '/static/image/viptier/platinum.png' },
      { id: 8, src: '/static/image/viptier/diamond.png' }
    ],
    slotpercentages: ['0.10', '0.20', '0.30', '0.50', '0.80', '1.00'],
    casinopercentages: ['0.10', '0.20', '0.30', '0.50', '0.80', '1.00'],
    sportpercentages: ['0.10', '0.20', '0.30', '0.60', '0.80', '1.00'],
    freeCoinspercentages: [1, 1, 2, 5, 8, 10],
    turnoverrequirements: ['80,000', '2,500,000', 'N/A', 'N/A', 'N/A', 'Special Invitation'],
    upgradebonuses: ['200', '5,000', '20,000', '60,000', '100,00', 'Special Invitation'],
    downgradecriterias: ['N/A', 'N/A', 'Monthly Reset', 'Monthly Reset', 'Monthly Reset', 'Special Invitation'],
    // levelNames: [
    //   locale.getMessage('label.newMember'),
    //   locale.getMessage('label.bronze'),
    //   locale.getMessage('label.silver'),
    //   locale.getMessage('label.gold'),
    //   locale.getMessage('label.emerald'),
    //   locale.getMessage('label.sapphire'),
    //   locale.getMessage('label.platinum'),
    //   locale.getMessage('label.diamond')
    // ],
    levelName: ['newMember', 'bronze', 'silver', 'gold', 'emerald', 'sapphire', 'platinum', 'diamond'],
    nextLv: 0,
    progressRequired: 0,
    empties: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '],
    oneMonthDeposit: ['', '৳35,000', '৳125,000', '৳300,000', '৳500,000', '৳1,000,000', '৳3,00,000', '৳6,00,000'],
    threeMonthDeposit: ['', '৳105,000', '৳375,000', '৳900,000', '৳1,500,000', '৳3,000,000', '৳9,000,000', '৳18,000,000'],
    upgradeBonus: ['', '৳688', '৳3,888', '৳5,888', '৳8,888', '৳18,888', '৳26,888', '৳32,888'],
    bdBonus: ['৳388', '৳688', '৳1,888', '৳3,888', '৳6,888', '৳12,888', '৳18,888', '৳26,888'],
    weeklyBonus: ['', '', '৳688', '৳888', '৳1,888', '৳3,888', '৳6,888', '৳8,888'],
    slotPercentages: ['', '0.30%', '0.40%', '0.50%', '0.60%', '0.70%', '0.80%', '1.00%'],
    otherPercentages: ['', '0.10%', '0.20%', '0.30%', '0.40%', '0.50%', '0.60%', '0.80%'],
    dedicatedVipLiveChat: [0, 0, 0, 1, 1, 1, 1, 1],
    depositWithdrawPriority: [0, 0, 0, 1, 1, 1, 1, 1],
    specialGift: [0, 0, 1, 1, 1, 1, 1, 1],
    vipEvents: [0, 0, 0, 1, 1, 1, 1, 1],
    exclusiveAccess: [0, 0, 0, 0, 0, 1, 1, 1],
    depositWithdrawOption: [0, 0, 0, 0, 0, 1, 1, 1],
    dedicatedVipManager: [0, 0, 0, 0, 0, 0, 1, 1],
    threeMonthDepositReq: ['', '', '৳187,500', '৳450,000', '৳750,000', '৳1,500,000', '৳4,500,000', '৳9,000,000'],
    threeMonthToReq: ['', '', '৳937,500', '৳2,250,000', '৳3,750,000', '৳7,500,000', '৳22,500,000', '৳45,000,000'],
    memberRenewal: ['Lifetime', 'Lifetime', 'Monthly', 'Monthly', 'Monthly', 'Monthly', 'Monthly', 'invitedonly']
  }),
  created() {
    // this.getVipTierProgress()
    this.metaTag()
    this.tierSettingsDetail.diamond.monthlyDepositRequirement = this.monthlyDepositRequirementDiamond
    if (this.isLoggedIn) {
      this.getVipTierProgress()
    }
  },
  computed: {
    memberVipTierProgress() {
      return this.$store.state.member.vipTierProgress.pairing
    },
    monthlyDepositRequirementDiamond() {
      return locale.getMessage('label.invitedonly')
    },
    imagesVip() {
      return [
        { id: 1, src: '/static/image/viptier/bronze.png', name: 'Bronze', amount: '৳60,000' },
        { id: 2, src: '/static/image/viptier/gold.png', name: 'Gold', amount: '৳300,000' },
        { id: 3, src: '/static/image/viptier/sapphire.png', name: 'Sapphire', amount: '৳1,000,000' },
        { id: 4, src: '/static/image/viptier/diamond.png', name: 'Diamond', amount: locale.getMessage('label.invitedonly') },
        { id: 5, src: '/static/image/viptier/platinum.png', name: 'Platinum', amount: '৳3,000,000' },
        { id: 6, src: '/static/image/viptier/emerald.png', name: 'Emerald', amount: '৳500,000' },
        { id: 7, src: '/static/image/viptier/silver.png', name: 'Silver', amount: '৳125,000' }
      ]
    },
    levelNames() {
      return [
        this.$t('label.newMember'),
        this.$t('label.bronze'),
        this.$t('label.silver'),
        this.$t('label.gold'),
        this.$t('label.emerald'),
        this.$t('label.sapphire'),
        this.$t('label.platinum'),
        this.$t('label.diamond')
      ]
    },
    currentTier() {
      return this.levelName[this.currentIndex]
    },
    currentTierDetails() {
      return this.tierSettingsDetail[this.currentTier.toLowerCase()]
    },
    upgradeBonuses() {
      return ['', '688', '2,288', '5,888', '8,888', '18,888', '32,888', '38,888']
    },
    oneMonthDeposits() {
      return ['', '৳60,000', '৳125,000', '৳300,000', '৳500,000', '৳1,000,000', '৳3,000,000', locale.getMessage('label.invitedonly')]
    },
    threeMonthDepositReqs() {
      return ['', '0', '62,500', '150,000', '250,000', '500,000', '1,500,000', '3,000,000']
    },
    bdBonuses() {
      return ['', '688', '1,888', '3,888', '6,888', '12,888', '18,888', '26,888']
    },
    depositRewardCoins() {
      return ['1%', '1%', '1.2%', '1.5%', '1.5%', '2%', '2%', '2%']
    },
    weeklyBonuses() {
      return ['5.88', '8.88', '8.88', '8.88', '10.88', '10.88', '11.88', '11.88']
    },
    weeklyBonusesMax() {
      return ['888', '688', '1,888', '3,888', '8,888', '18,888', '38,888', '88,888']
    },
    // capitalizedTierName() {
    //   return name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    // },
    // slotpercentage() {
    //   const percentageMap = {
    //     1: '0.10%',
    //     2: '0.30%',
    //     3: '0.60%',
    //     4: '0.80%',
    //     5: '1.00%',
    //     6: '1.20%'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // casinopercentage() {
    //   const percentageMap = {
    //     1: '0.10%',
    //     2: '0.20%',
    //     3: '0.30%',
    //     4: '0.50%',
    //     5: '0.80%',
    //     6: '1.00%'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // sportpercentage() {
    //   const percentageMap = {
    //     1: '0.10%',
    //     2: '0.20%',
    //     3: '0.30%',
    //     4: '0.60%',
    //     5: '0.80%',
    //     6: '1.00%'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // freeCoinspercentage() {
    //   const percentageMap = {
    //     1: 1,
    //     2: 1,
    //     3: 2,
    //     4: 5,
    //     5: 8,
    //     6: 10
    //   }
    //
    //   let currentPrecent = percentageMap[this.level] || ''
    //   currentPrecent = currentPrecent + '%'
    //   //mkt request to use english as standard
    //   // if(currentPrecent!==''){
    //   //   //convert into locale string
    //   //   if(this.language==='bd'){
    //   //     currentPrecent = currentPrecent.toLocaleString('bn-BD')+'%'
    //   //   }else{
    //   //     currentPrecent = currentPrecent +'%'
    //   //   }
    //   // }
    //
    //   return currentPrecent
    // },
    // depositrequirement() {
    //   const percentageMap = {
    //     1: '10,000',
    //     2: '500,000',
    //     3: '3,000,000',
    //     4: '8,000,000',
    //     5: '15,000,000',
    //     6: 'Special Invitation'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // turnoverrequirement() {
    //   const percentageMap = {
    //     1: '80,000',
    //     2: '2,500,000',
    //     3: 'N/A',
    //     4: 'N/A',
    //     5: 'N/A',
    //     6: 'Special Invitation'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // upgradebonus() {
    //   const percentageMap = {
    //     1: '200',
    //     2: '5,000',
    //     3: '20,000',
    //     4: '60,000',
    //     5: '100,000',
    //     6: 'Special Invitation'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // downgradecriteria() {
    //   const percentageMap = {
    //     1: 'N/A',
    //     2: 'N/A',
    //     3: 'Monthly Reset',
    //     4: 'Monthly Reset',
    //     5: 'Monthly Reset',
    //     6: 'Special Invitation'
    //   }
    //   return percentageMap[this.level] || ''
    // },
    // imgSource() {
    //   return '/static/svg/vipassets/vip_' + this.tierColorThemeName + '.svg'
    // },
    // formattedValue() {
    //   const currencySymbol = this.currencySymbolMapp.currencySymbolMap[this.currentCurrency]
    //   const value = 10000
    //   return `${currencySymbol}${value}`
    // },
    // currentDate() {
    //   return new Date()
    // },
    // referralOverview() {
    //   return this.$store.state.referral.overview
    // },
    //
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
    // totalPoint() {
    //   return this.overview.downline_lv1 + this.overview.downline_lv2 + this.overview.downline_lv3
    // },
    // redeemCommissionDetailResponseComplete() {
    //   return this.$store.state.referral.redeemCommissionResponse.complete
    // },
    // referralConfig() {
    //   return this.$store.state.referral.config
    // },
    // memberInfo() {
    //   return this.$store.state.member.info
    // },
    // webShareApiSupported() {
    //   return navigator.share
    // },
    // memberVipTierProgress() {
    //   return this.$store.state.member.vipTierProgress.pairing
    // },
    // vipTierUpgrade() {
    //   return this.$store.state.member.vipTierProgress.upgrade
    // }
  },
  watch: {
    memberVipTierProgress() {
      this.currentIndex = this.memberVipTierProgress.level_sequence
    }
    // referralOverview() {
    //   this.overview = this.$store.state.referral.overview
    //   this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code
    //   this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    // },
    // redeemCommissionDetailResponseComplete() {
    //   let response = this.$store.state.referral.redeemCommissionResponse
    //   if (response.complete) {
    //     this.redeemCommissionResponseCompleteDialog(response)
    //     this.$store.dispatch(`${REFERRAL_RESET_COMMISSION}`)
    //   }
    // },
    // memberVipTierProgress() {
    //   let vipTierProgress = this.$store.state.member.vipTierProgress
    //   if (Object.keys(vipTierProgress.pairing).length > 0) {
    //     let curPairing = vipTierProgress.pairing
    //     //progress found
    //     this.showProgress = true
    //     switch (curPairing.level_sequence) {
    //       case 1:
    //         this.tierColorThemeName = 'bronze'
    //         break
    //       case 2:
    //         this.tierColorThemeName = 'silver'
    //         break
    //       case 3:
    //         this.tierColorThemeName = 'gold'
    //         break
    //       case 4:
    //         this.tierColorThemeName = 'platinum'
    //         break
    //       default:
    //         this.tierColorThemeName = 'bronze'
    //         this.tierBarBgColor = '#1867c0'
    //     }
    //     this.tierBarBgColor = 'viptier' + this.tierColorThemeName
    //     this.tierName = curPairing.name
    //     this.currentLv = this.memberVipTGierProgress.level_sequence
    //     this.nextLv = this.memberVipTierProgress.level_sequence + 1
    //   } else {
    //     //no progress
    //     this.showProgress = false
    //   }
    // },
    // memberVipTierProgress() {
    //   let vipTierProgress = this.$store.state.member.vipTierProgress
    //   if (vipTierProgress && vipTierProgress.pairing) {
    //     if (Object.keys(vipTierProgress.pairing).length > 0) {
    //       let curPairing = vipTierProgress.pairing
    //       this.showProgress = true
    //       this.tierName = curPairing.name
    //       this.currentLv = curPairing.level_sequence
    //       this.nextLv = curPairing.level_sequence + 1
    //
    //       // Set current progress based on the level_sequence
    //       switch (curPairing.level_sequence) {
    //         case 1:
    //           ;(this.currentProgress = 11), (this.DesktopcurrentProgress = 13)
    //           break
    //         case 2:
    //           ;(this.currentProgress = 27), (this.DesktopcurrentProgress = 27)
    //           break
    //         case 3:
    //           ;(this.currentProgress = 44), (this.DesktopcurrentProgress = 44)
    //           break
    //         case 4:
    //           ;(this.currentProgress = 60), (this.DesktopcurrentProgress = 60)
    //           break
    //         case 5:
    //           ;(this.currentProgress = 78), (this.DesktopcurrentProgress = 78)
    //           break
    //         case 6:
    //           ;(this.currentProgress = 100), (this.DesktopcurrentProgress = 100)
    //           break
    //         default:
    //           ;(this.currentProgress = 0), (this.DesktopcurrentProgress = 0)
    //       }
    //     } else {
    //       this.showProgress = false
    //     }
    //   }
    // },
    //
    // isLoggedIn() {
    //   if (this.isLoggedIn) {
    //     this.getVipTierProgress()
    //   }
    // }
  },
  methods: {
    getVipTierProgress() {
      this.$store.dispatch(MEMBER_VIP_TIER_PROGRESS)
    },
    goToPromotionVipTier() {
      this.$router.push({
        name: ROUTE_NAME.PROMOTION,
        params: { tab: 'vipPromotion' }
      })
    },
    truncatedItemName(itemName) {
      const charLimit = 3 // Set your desired character limit here
      if (itemName.length > charLimit) {
        return itemName.substring(0, charLimit) + '...'
      } else {
        return itemName
      }
    },
    toggleFaq() {
      this.showFaq = !this.showFaq
    },
    toggleTnc() {
      this.showTnc = !this.showTnc
    },
    getTextColor() {
      return this.currentTier ? this.currentTier : '' // Fallback
    },
    getTierIndex(state) {
      switch (state) {
        case 'past':
          return this.currentIndex > 0 ? this.currentIndex - 1 : null
        case 'past2':
          return this.currentIndex > 0 ? this.currentIndex - 2 : null
        case 'current':
          return this.currentIndex
        case 'next':
          return this.currentIndex < this.levelName.length - 1 ? this.currentIndex + 1 : null
        case 'next2':
          return this.currentIndex < this.levelName.length - 2 ? this.currentIndex + 2 : null
        default:
          return this.currentIndex
      }
    },
    getImageSrc(state) {
      const index = this.getTierIndex(state)
      if (index === null || index < 0 || index >= this.levelName.length) {
        return '' // Return an empty string if index is out of bounds
      }
      const color = this.levelName[index]
      return `${color}`
    },
    getImageSrcHalfLeft(state) {
      const index = this.getTierIndex(state)
      if (index === null) {
        return ''
      }
      const color = this.levelName[index]
      return `/static/image/viptier/${color}_left.png`
    },
    getImageSrcHalfRight(state) {
      const index = this.getTierIndex(state)
      if (index === null) {
        return ''
      }
      const color = this.levelName[index]
      return `/static/image/viptier/${color}_right.png`
    },
    previousTier() {
      if (!this.isFirstTier) {
        this.currentTierIndex--
      }
    },
    nextTier() {
      if (!this.isLastTier) {
        this.currentTierIndex++
      }
    },
    formatCurrency(amount) {
      // Use currencySymbolMapp to get the symbol based on currentCurrency
      const symbol = this.currencySymbolMapp(this.currentCurrency)
      return amount ? `${symbol}${amount}` : '' // Add currency symbol only if amount is not empty
    },
    getMaxWidth(index) {
      const totalIcons = this.images.length
      const middleIndex = Math.floor(totalIcons / 2)

      if (index === 0 || index === totalIcons - 1) {
        // First and last icon (0 and 6 in your example)
        return 120
      } else if (index === 1 || index === totalIcons - 2) {
        // Second and second-to-last icon (1 and 5 in your example)
        return 140
      } else if (index === 2 || index === totalIcons - 3) {
        // Third and third-to-last icon (2 and 4 in your example)
        return 160
      } else if (index === middleIndex) {
        // Middle icon (3 in your example)
        return 180
      } else {
        // Other icons
        return 120 // You can set a default value or handle this case based on your requirements
      }
    },
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    showPreviousLevel() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    showNextLevel() {
      if (this.currentIndex < this.levelName.length - 1) {
        this.currentIndex++
      }
    }
  }
}
</script>

<style lang="scss">
.findMoreButton {
  border-radius: 25px !important;
}
.tier-nextPass {
  min-width: 20px !important;
}
.nameCover {
  background: #ffce01;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
  width: 90px;
  text-align: center;
}
.nameCoverETC {
  border: 1px solid #575757;
  color: #575757;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
  width: 60px;
  text-align: center;
}
.fade {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%; /* Adjust this to control the fade starting point */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none; /* Ensures the gradient overlay is non-interactive */
}
.otherTierImage {
  opacity: 0.25;
}
.text-container {
  position: absolute;
  bottom: 0; /* or any other position you need */
  left: 0; /* or any other position you need */
  right: 0; /* or any other position you need */
  top: 0; /* or any other position you need */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px; /* Adjust the padding as needed */
}
.tncFaqTitle {
  font-size: 18px !important;
}
.col-padding {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 15px !important;
  .headerTable {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
.col-border {
  padding-left: 15px !important;
  display: flex;
  justify-content: start;
  align-items: center;
}
.border_left {
  //height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tierDetail-card {
  background: #2c2c2c !important;
}
.coveredRow {
  background: #383838;
  border: 2px solid #575757;
  border-radius: 15px;
}
.uncoveredRow {
  border: 2px solid transparent;
}
.headerTable {
  font-size: 18px;
}
.vip-tiermobile {
  padding: 15px;
  background: #2c2c2c;
}
.vipGirlImg {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.be-vip-container {
  border: 2px solid #ffce01;
  border-radius: 25px;
  background: #1e1e1e;
}
.level-Names {
  background: white;
  border-radius: 15px;
  color: black !important;
  padding: 5px;
  width: 85px;
}
.category-Title {
  font-size: 18px !important;
}
.bigger-col {
  width: 25%; /* Adjust as needed */
}
.descriptionVipText {
  font-size: 15px;
}
.bigger-img {
  width: 100%; /* Adjust as needed */
  height: auto; /* Ensures image proportions are maintained */
}
.cashGirl {
  position: absolute;
  top: 30%;
  right: 3%;
  height: 70%;
}
.tncFaqCol {
  border: 2px solid #ffce01;
  max-width: 45%;
  border-radius: 10px;
  opacity: 1 !important;
  background-color: rgba(30, 30, 30, 0.7);
}
.rankName {
  background-color: #ffffff80;
  border-radius: 15px;
  height: 35px;
  width: 90px;
}
.vipTable_bg {
  background-color: #3d3d3d;
  border-radius: 20px;
}
.level-col {
  display: flex;
  align-items: center;
  //width: 100px;
  //height: 100px;
}

//.level-col.scale-up {
//  transform: scale(1.2); /* Adjust the scale factor as needed */
//}
.joinVipText {
  font-size: 18px;
}
.height-of-tier {
  height: 350px;
}
.menuBar {
  position: absolute;
  z-index: 5;
  width: 100%;
}
//.menuBarWrapper {
//  max-width: 1320px;
//}
.be-vip {
  //background: #1e1e1e;
}
.text-vip {
  position: absolute;
  bottom: 1%;
  left: 25%;
  width: 600px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
  text-align: left;
}
.img-vip {
  position: absolute;
  bottom: 20%;
  left: 24%;
  transform: translate(-50%, -50%);
  text-align: left;
}
.infospace {
  width: 55%;
}
.vip-all-tier-desktop {
  height: 132px !important;
  min-width: 48px !important;
  width: 96px !important;
  border-radius: 0px;
  overflow: inherit;
  margin: 10px 28px 0px;
  display: flex;
  flex-direction: column;
}
.vipcard_asset {
  height: 250px;
  width: 250px;
}
.tierpng {
  height: 90px !important;
  min-width: 48px !important;
  width: 80px !important;
}
.tierpngHalf {
  height: 90px !important;
  min-width: 24px !important;
  width: 40px !important;
}
.middleTier {
  padding: 0 6px !important;
}
.btnleft {
  background-color: transparent !important;
  z-index: 1;
  color: #fff !important;
  padding: 0 8px !important;
}

.btnright {
  background-color: transparent !important;
  z-index: 1;
  color: #fff !important;
  padding: 0 8px !important;
}
.title3 {
  font-size: 18px;
  color: #ff860c;
}
.leftside {
  justify-content: center;
  align-items: center;
  display: flex;
}
.downsideFAQ {
  border-radius: 10px;
  width: 100%;
  background: #002b16;
  padding: 20px;
  p {
    font-size: 12px;
  }
}
.upsideTNC {
  border-radius: 10px;
  width: 100%;
  background: #002b16;
  padding: 20px;
  p {
    font-size: 12px;
  }
}

.lines {
  color: #00ffb1;
  font-size: 24px;
  margin-bottom: 10px;
}
.linesmobile {
  justify-content: center;
  display: flex;
  color: #00ffb1;
  font-size: 14px;
  margin-bottom: 10px !important;
}
.v-progress-linear__determinate {
  border-radius: 13px;
  //height: 12px;
}
.rightside {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 8%;
}
.custom-divider {
  padding: 0px !important;
  border-width: 0.8px !important;
  border-color: #00ffb1 !important;
  //border-image: linear-gradient(45deg,  #002A20, #00FFB1) 1;
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
}
.v-progress-linear__background {
  background-color: #00543d !important;
  opacity: 1 !important;
}

.viptitle {
  color: #ff860c;
  text-align: center;
  font-size: 18px;
  //background-color: #0a0637;
}
.titledesktop {
  color: #ffffff;
  text-align: left;
  font-size: 18px;
  //background-color: #0a0637;
  padding-left: 20px;
}
.title2 {
  color: #ff860c;
  font-size: 18px;
  margin-top: 25px;
}

.titlewithbackgroundcolor {
  font-size: 18px;
  color: #ffffff;
  padding-left: 20px;
  background-color: #003b26;
  padding-top: 10px;
  padding-bottom: 10px;
  //margin-left: -20px;
  //margin-right: -20px;
}
.border_left {
  border-left: 3px solid #ffce01;
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border_left:first-child {
  border-left: none;
}
th,
td {
  padding: 8px;
}

.v-expand-transition p {
  font-size: 12px;
}
.faqbtn,
.tncbtn {
  background-color: #ffce01 !important;
  justify-content: start;
}

.triangle {
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #000 transparent;
}

.triangle.up {
  transform: translate(-50%, -50%) rotate(180deg);
  top: 60%;
}

.triangle.down {
  transform: translate(-50%, -50%);
  top: 40%;
}
.faq-tnc-container {
  width: 100%;
  background-color: #ffce01 !important;
  border-radius: 5px;
}
.vip-tier {
  padding: 20px 20px 0px;
  color: white;
}
.vip-tierdesktop {
  padding: 2% 12% !important;
  color: white;
  background: #1e1e1e;
}
.vip-level-upgrade-info {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: white;
}
.vip-level-upgrade-info-inner {
  background-image: url('/static/svg/vipassets/How_to_Join_MobileBg.jpg');
  background-size: contain;
  background-position-y: bottom;
  height: 590px;
  width: 100%;
  padding: 15px;
  border: 2px solid #ff860c;
  border-radius: 5px;
}
.vip-level-upgrade-info-inner-desktop {
  background-image: url('/static/svg/vipassets/How_to_Join_DesktopBg.jpg');
  background-size: cover;
  height: 400px;
  width: 100%;
  padding: 15px;
  border: 2px solid #ff860c;
  border-radius: 5px;
}
.be-kk-vip {
  //background-color: #060936;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 24px !important;
  padding-top: 20px;
}
.vip-benefits {
  //background-color: #060936;
  color: #ff860c;
  padding-top: 20px;
  margin-bottom: 16px;
}
.vip-tier-mobile {
  display: none !important;
}
.upgrade-requirement {
  //background-color: #060936;
  color: #ff860c;
  margin-bottom: 16px;
}
.retention-requirement {
  //background-color: #060936;
  color: #ff860c;
}
.pp {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px !important;

  &.club {
    padding-top: 5px;
    color: #c16d2d !important;
    text-transform: none;
  }
  &.diamond {
    padding-top: 5px;
    color: #b4b5b5 !important;
    text-transform: none;
  }
  &.heart {
    padding-top: 5px;
    color: #d9974a !important;
    text-transform: none;
  }
  &.spade {
    padding-top: 5px;
    color: #66c6ce !important;
    text-transform: none;
  }
  &.ace {
    padding-top: 5px;
    color: #ca8be1 !important;
    text-transform: none;
  }
  &.raja {
    padding-top: 5px;
    color: #ffffff !important;
    text-transform: none;
  }
}

.lefttext {
  text-align: left;
  color: white;
  font-size: 12px;
}
.lefttextdesktop {
  text-align: left;
  color: white;
  padding: 20px 0px;
  font-size: 14px;
  margin-bottom: 0px !important;
}
.righttext {
  text-align: right;
  color: #ffffff;
  font-size: 12px;
}
.righttextdesktop {
  text-align: center;
  color: white;
  font-size: 14px;
}
.vip-level-upgrade-info p,
.be-kk-vip p {
  font-size: 12px;
}
.blue-text {
  color: #0047ff;
}
.progress-details-col {
  font-size: 15px;
}
.tier-all-png {
  height: auto !important;
  min-width: 48px !important;
  width: 70px !important;
  margin: 7px 11px 0px;
  display: flex;
  flex-direction: column;
}
.tier-all-png-desktop {
  border-radius: 0px;
  overflow: inherit;
  width: 95px !important;
  //height: 90px !important;
  margin: 43px 2.7% 0px;
  display: flex;
  flex-direction: column;
}
.faqspan {
  font-size: 15px;
}
.headertext {
  font-size: 24px;
  color: #fff;
  text-align: start;
  padding-bottom: 20px;
  font-weight: normal !important;
}
.descriptionText {
  font-size: 12px;
}
.tableText {
  font-size: 14px;
}
//@media (max-width: 1600px) {
//  .vipcard_asset{
//    height: 200px;
//    width: 200px;
//  }
//  .tier-all-png-desktop {
//    width: 135px !important;
//    height: 80px !important;
//    margin: 3px 2px 0px;
//  }
//  .vip-all-tier-desktop {
//    height: 120px !important;
//    min-width: 48px !important;
//    width: 140px !important;
//    margin: 7px 6px 0px;
//  }
//}
//@media (max-width: 1500px){
//  .tier-all-png-desktop {
//    width: 115px !important;
//    height: 74px !important;
//    margin: 3px 4px 0px;
//  }
//  .vip-all-tier-desktop {
//    height: 105px !important;
//    min-width: 48px !important;
//    width: 125px !important;
//    margin: 7px 6px 0px;
//  }
//}
//
//
@media (min-width: 1600px) {
  .height-of-tier {
    height: 400px;
  }
}
@media (min-width: 2000px) {
  .height-of-tier {
    height: 450px;
  }
}

@media (max-width: 1500px) {
  .tier-all-png-desktop {
    width: 102px !important;
    //height: 60px !important;
    margin: 43px 13px 0px;
  }
  .vip-all-tier-desktop {
    height: auto !important;
    max-width: 100px !important;
    margin: 7px 22px 0px;
  }
}
@media (max-width: 1400px) {
  .cashGirl {
    position: absolute;
    top: 30%;
    right: -2%;
    height: 70%;
  }
  .text-vip {
    bottom: -7%;
    left: 27%;
    width: 600px;
  }
  .img-vip {
    bottom: 14%;
    left: 26%;
    width: 600px;
  }
  .tier-all-png-desktop {
    width: 96px !important;
    //height: 55px !important;
    margin: 43px 9px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 17px 0px;
  }
}
@media (min-width: 1700px) {
  .img-vip {
    left: 24%;
    bottom: 25%;
    width: 700px;
  }
  .text-vip {
    left: 25%;
    bottom: 6%;
    width: 700px;
  }
  .joinVipText {
    font-size: 21px;
  }
}
//
//@media (max-width: 1280px){
//  .tier-all-png-desktop {
//    width: 106px !important;
//    height: 60px !important;
//    margin: 3px 3px 0px;
//  }
//}
@media screen and (max-width: 1280px) {
  .text-vip {
    left: 32%;
    bottom: -9%;
  }
  .img-vip {
    left: 32%;
    bottom: -6%;
  }
  .tier-all-png-desktop {
    width: 90px !important;
    //height: 50px !important;
    margin: 53px 9px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 13px 0px;
  }
  .tier-img {
    height: auto !important;
    max-width: 80px !important;
  }
}

@media (max-width: 1190px) {
  .tier-all-png-desktop {
    width: 72px !important;
    //height: 43px !important;
    margin: 53px 11px 0px;
  }
  .vip-all-tier-desktop {
    //height: 144px !important;
    //width: 105px !important;
    margin: 7px 12px 0px;
    max-width: 90px !important;
  }
}

@media (max-width: 1095px) {
  .headertext {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .descriptionVipText {
    font-size: 12px;
    margin-bottom: 6px !important;
  }
  .text-vip {
    left: 30%;
  }
  .title2 {
    margin-bottom: 0px !important;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    //height: 51px !important;
    margin: 53px 10px 0px;
  }
  .tier-img {
    max-width: 77px !important;
  }
  .vip-all-tier-desktop {
    max-width: 83px !important;
  }
  .infospace {
    width: 65%;
  }
}
@media (max-width: 1000px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    //height: 43px !important;
    margin: 53px 6px 0px;
  }
  .tier-img {
    max-width: 65px !important;
  }
  .infospace {
    width: 70%;
  }
  .vip-all-tier-desktop {
    height: 106px !important;
    width: 77px !important;
    margin: 10px 11px 7px;
  }
}
@media (max-width: 950px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    width: 70px !important;
    //height: 43px !important;
    margin: 53px 5px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 8px 0px;
  }
}
@media (max-width: 900px) {
  .infospace {
    width: 80%;
  }
  .vip-level-upgrade-info-inner-desktop {
    width: 100%;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    width: 57px !important;
    //height: 35px !important;
    margin: 53px 5px 0px;
  }
  .tier-img {
    max-width: 59px !important;
  }
  .vip-all-tier-desktop {
    //height: 60px !important;
    //min-width: 48px !important;
    //width: 70px !important;
    margin: 7px 4px 0px;
  }
  .text-vip {
    left: 35%;
    font-size: 25px;
  }
}
@media screen and (min-width: 650px) and (max-width: 1300px) {
  .vip-tier-mobile-banner {
    display: none !important;
  }
  .vip-tier-mobile {
    display: block !important;
  }
}
@media (max-width: 850px) {
  .headertext {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .descriptionVipText {
    font-size: 9px;
    margin-bottom: 6px !important;
  }
  .beVipText {
    font-size: 18px;
    padding-bottom: 5px;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .infospace {
    width: 100%;
  }
  .vip-all-tier-desktop {
    height: 92px !important;
    min-width: 48px !important;
    width: 67px !important;
    margin: 7px 4px 0px;
  }
  .tier-all-png-desktop {
    width: 50px !important;
    //height: 29px !important;
    margin: 53px 5px 0px;
  }
  .tier-img {
    max-width: 48px !important;
  }
}

@media (max-width: 540px) {
  .findMoreButton {
    border-radius: 15px !important;
  }
  .headerTable {
    font-size: 14px;
  }
  .tableText {
    font-size: 12px;
  }
  .descriptionVipText {
    font-size: 12px;
    margin-bottom: 6px !important;
  }
  .beVipText {
    font-size: 16px;
  }
  .title3 {
    font-size: 16px;
  }
  .title2 {
    font-size: 16px;
  }
  .titlewithbackgroundcolor {
    font-size: 16px;
  }
  .h1 {
    font-size: 16px;
  }
}

@media (max-width: 500px) and (min-width: 451px) {
  .descriptionVipText {
    font-size: 13.5px;
    margin-bottom: 6px !important;
  }
  .headertext {
    font-size: 16px;
  }
  .beVipText {
    padding-bottom: 15px;
  }
}

@media (max-width: 650px) and (min-width: 490px) {
  .descriptionVipText {
    font-size: 16px;
    margin-bottom: 6px !important;
  }
  .headertext {
    font-size: 18px;
  }
  .beVipText {
    padding-bottom: 15px;
  }
}
@media (max-width: 490px) {
  .title3,
  .faqspan {
    font-size: 14px !important;
  }
  .title2 {
    font-size: 14px;
  }
  .titlewithbackgroundcolor {
    font-size: 14px;
  }
  .headertext {
    font-size: 17px;
  }
  .lefttext,
  .righttext,
  .vip-level-upgrade-info p,
  .be-kk-vip p {
    font-size: 12px;
  }
  .pp {
    font-size: 12px;
  }
  .btnleft {
    height: 30px !important;
    min-width: 10px !important;
  }
  .btnright {
    height: 30px !important;
    min-width: 10px !important;
  }
}

@media (max-width: 440px) {
  .beVipText {
    font-size: 12px;
  }
  .nameCover {
    padding: 2px;
    font-size: 12px;
    width: 65px;
  }
  .nameCoverETC {
    padding: 2px;
    font-size: 12px;
    width: 48px;
  }
  .vip-tier,
  .titlewithbackgroundcolor {
    padding: 8px;
  }
  .tierpng {
    height: 75px !important;
    min-width: 36px !important;
    width: 70px !important;
  }
  .tierpngHalf {
    height: 75px !important;
    min-width: 24px !important;
    width: 35px !important;
  }
  //.lefttext, .righttext ,.vip-level-upgrade-info p,
  //.be-kk-vip p, .pp {
  //  font-size: 9px;
  //}
  .title3,
  .faqspan {
    font-size: 12px !important;
  }
  .vip-level-upgrade-info-inner {
    height: 780px;
  }
  .titlewithbackgroundcolor {
    font-size: 12px;
  }
  .headertext {
    font-size: 12px;
  }
  .title2 {
    font-size: 12px;
  }
  .tier-all-png {
    margin: 7px 2% 0px;
  }
  .btnleft {
    height: 25px !important;
    min-width: 10px !important;
  }
  .btnright {
    height: 25px !important;
    min-width: 10px !important;
  }
}
@media (max-width: 390px) {
  .headerTable {
    font-size: 12px;
  }
  .tableText {
    font-size: 10px;
  }
  .descriptionVipText {
    font-size: 11px;
    margin-bottom: 6px !important;
  }
  .headertext {
    font-size: 11px;
  }
  .beVipText {
    padding-bottom: 5px;
  }
  .middleTier {
    padding: 0px !important;
  }
  .tierpng {
    height: 65px !important;
    min-width: 36px !important;
    width: 55px !important;
  }
  .tierpngHalf {
    height: 65px !important;
    min-width: 24px !important;
    width: 28px !important;
  }
}
@media (max-width: 375px) {
  .beVipText {
    font-size: 10px;
  }
  .tierpng {
    height: 60px !important;
    min-width: 36px !important;
    width: 50px !important;
  }
  .tierpngHalf {
    height: 60px !important;
    min-width: 25px !important;
    width: 25px !important;
  }
  .btnleft {
    height: 20px !important;
    min-width: 10px !important;
  }
  .btnright {
    height: 20px !important;
    min-width: 10px !important;
  }
  .tier-all-png {
    margin: 7px 1% 0px;
    width: 39px !important;
  }
  .progress-details-col {
    font-size: 15px;
  }
}
@media (max-width: 340px) {
  .headerTable {
    font-size: 11px;
  }
  .descriptionVipText {
    font-size: 9px;
    margin-bottom: 6px !important;
  }
  .middleTier {
    padding: 0px !important;
  }
  .tierpng {
    height: 50px !important;
    min-width: 36px !important;
    width: 45px !important;
  }
  .tierpngHalf {
    height: 50px !important;
    min-width: 23px !important;
    width: 23px !important;
  }
  .nameCover {
    padding: 2px;
    font-size: 8px;
    width: 45px;
  }
  .nameCoverETC {
    padding: 2px;
    font-size: 8px;
    width: 45px;
  }
}

@media (max-width: 280px) {
  .tier-all-png {
    height: auto !important;
    min-width: 35px !important;
    width: 35px !important;
    margin: 7px 1% 0px;
  }
  .btnleft {
    height: 15px !important;
    width: 10px !important;
  }
  .btnright {
    height: 15px !important;
    width: 10px !important;
  }
  .tierpng {
    height: 83px !important;
    min-width: 48px !important;
    width: 98px !important;
  }
  .vip-level-upgrade-info-inner {
    height: 834px;
  }
}
</style>
