import {apiHelper,uiHelper} from '@/util'
import {API, SHARED} from '@/constants/constants'

export const cmsService = {
    getBanner,
    getCmsBonus,
    getAngpaoChance,
    claimAngpao,
    getBonusCategories,
    getRegisterPageBanner
}

const CMS = {
    BANNER: 'banner',
    REGISTER_PAGE_BANNER: 'register_page_banners',
    CMS_CONTENT: 'cmscontent',
    CMS_API_URL: API.CMS_URL,
    ANGPAO_CHANGE: 'angpau/unclaimed',
    ANGPAO_CLAIM: 'angpau/claim',
    BONUS_CATEGORIES: 'bonus_categories',
}

function claimAngpao(){
    return apiHelper().post(`/${CMS.ANGPAO_CLAIM}`)
}

function getBonusCategories(filter) {
    return apiHelper().get(`${CMS.CMS_API_URL}/${CMS.BONUS_CATEGORIES}`, { params: filter })
}

function getAngpaoChance() {
    return apiHelper().get(`/${CMS.ANGPAO_CHANGE}`)
}

function getBanner() {
    const requestOptions = {
        params: {
            is_live: 1,
            is_active: 1,
            currency: uiHelper.getCurrency()
        }
    }
    return apiHelper().get(`${CMS.CMS_API_URL}/${CMS.BANNER}`, requestOptions)
}

function getCmsBonus(filter) {
    const requestOptions = {
        params: {
            is_live: 1,
            is_active: 1,
            categoryId: filter.cmsBonusCategoryId,
            currency: filter.currency
        }
    }
    return apiHelper().get(`${CMS.CMS_API_URL}/${CMS.CMS_CONTENT}`, requestOptions)
}
function getRegisterPageBanner(hash) {
    return apiHelper().get(`${CMS.CMS_API_URL}/${CMS.REGISTER_PAGE_BANNER}/${hash}`)
}