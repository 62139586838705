<template>
  <div>
    <!--    <span>inside membermenubar</span> -->
    <v-row class="bankPage-navigationBar hidden-sm-and-down">
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.DEPOSIT, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.DEPOSIT ? 'active-page' : ''">
          {{ $t(`label.deposit`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.WITHDRAWAL, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.WITHDRAWAL ? 'active-page' : ''">
          {{ $t(`label.withdrawal`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.CLAIM_VOUCHER, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.CLAIM_VOUCHER ? 'active-page' : ''">
          {{ $t(`label.voucher`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.PLAYER_HISTORY, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.PLAYER_HISTORY ? 'active-page' : ''">
          {{ $t(`label.playerHistory`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn
          class="nav-btn"
          :to="{ name: routeName.TRANSACTION_HISTORY, params: { lang: this.$route.params.lang } }"
          :class="this.$route.name === routeName.TRANSACTION_HISTORY ? 'active-page' : ''"
        >
          {{ $t(`label.history`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.TURNOVER, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.TURNOVER ? 'active-page' : ''">
          {{ $t(`label.turnoverHistory`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.PERSONAL, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.PERSONAL ? 'active-page' : ''">
          {{ $t(`label.myProfile`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.BANK_ACCOUNT, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.BANK_ACCOUNT ? 'active-page' : ''">
          {{ $t(`label.bankDetail`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.CHANGE_PASSWORD, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.CHANGE_PASSWORD ? 'active-page' : ''">
          {{ $t(`field.password`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.INBOX, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.INBOX ? 'active-page' : ''">
          {{ $t(`button.inbox`) }}
        </v-btn>
      </div>
      <!--     -->
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.PROFILE_REFERRAL_TIER, params: { lang: this.$route.params.lang } }" :class="isReferralPages ? 'active-page' : ''" active-class="active-page">
          {{ $t(`label.referral`) }}
        </v-btn>
      </div>
      <div class="nav-item" v-if="currentCurrency === 'BDT'">
        <v-btn class="nav-btn" :to="{ name: routeName.VIPTIER, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.VIPTIER ? 'active-page' : ''">
          {{ $t(`label.vipText`) }}
        </v-btn>
      </div>
      <div class="nav-item">
        <v-btn class="nav-btn" :to="{ name: routeName.WOF, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.WOF ? 'active-page' : ''">{{ $t(`label.wofv3`) }}</v-btn>
      </div>
      <div class="nav-item" v-if="currentCurrency === 'BDT'">
        <v-btn class="nav-btn" :to="{ name: routeName.REWARD_STORE_ITEM, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.REWARD_STORE_ITEM ? 'active-page' : ''">
          {{ $t(`mobileMenuTitle.rewards`) }}
        </v-btn>
      </div>
      <div class="nav-item" v-else>
        <v-btn class="nav-btn" :to="{ name: routeName.REWARDS, params: { lang: this.$route.params.lang } }" :class="this.$route.name === routeName.REWARDS ? 'active-page' : ''">
          {{ $t(`mobileMenuTitle.rewards`) }}
        </v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper } from '@/util'
export default {
  name: 'appMemberMenuBar',
  data: () => ({
    routeName: ROUTE_NAME,
    currentCurrency: uiHelper.getCurrency()
  }),
  created() {},
  computed: {
    isReferralPages() {
      return (
        this.$route.name == ROUTE_NAME.PROFILE_REFERRAL_REPORT ||
        this.$route.name == ROUTE_NAME.PROFILE_REFERRAL_REDEEM_HISTORY ||
        this.$route.name == ROUTE_NAME.PROFILE_REFERRAL_DOWNLINE_HISTORY ||
        this.$route.name == ROUTE_NAME.PROFILE_REFERRAL_TIER
      )
    }
  }
}
</script>

<style lang="scss">
.noGap {
  gap: 0px 5px !important;
}

.bankPage-navigationBar {
  display: flex;
  padding: 15px 15px;
  justify-content: start;
  gap: 10px 15px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0 0 0;
  flex-wrap: wrap;
  .nav-item {
    .nav-btn {
      font-family: 'Roboto', sans-serif !important;
      border-radius: 20px;
      background-color: transparent;
      text-transform: capitalize;
      letter-spacing: normal;
      font-size: 1rem;
      &.active-page {
        background-color: var(--v-newMobilePrimary-base) !important;
        font-weight: bold;
      }

      &.v-btn--active:before {
        //background-color
        opacity: 0 !important;
      }
    }
  }

  .theme--light.v-btn--active:hover::before,
  .theme--light.v-btn--active::before {
    //opacity: 1 !important;
  }
}
</style>
