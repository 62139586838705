<template>
  <div>
    <v-row no-gutters v-if="!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK && !isAndroidWebView" class="download-bar">
      <v-icon @click="hideDownloadBar()" class="mr-2">mdi-close</v-icon>
      <img class="mr-2" width="32" height="32" src="/static/image/layout/download-app.png" alt="download" />
      <v-col>
        <label>{{ $t(`label.downloadApp`) }}</label>
      </v-col>
      <v-btn @click="downloadApk" class="download-bar-button subtitle-1">{{ $t(`label.downloadAppBtn`) }}</v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="full-width hidden-md-and-up header-top mobile elevation-3">
        <!-- <v-toolbar class="hidden-md-and-up header-top mobile elevation-3"> -->
        <v-row no-gutters align="center">
          <v-col cols="12" class="px-3" style="">
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="1" class="text-left">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" height="18" width="22"></v-app-bar-nav-icon>
              </v-col>
              <v-col cols="9" class="mobile-header-logo">
                <router-link :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }" style="display: flex;align-items: center;">
                  <img src="/static/svg/bb88_logo_animation2.gif" alt width="215" height="45" />
                </router-link>
              </v-col>
              <v-col cols="2" class="text-right">
                <!--                <span>currency lancuage switcher</span>-->
                <!--                <app-login-embedded-->
                <!--                  v-if="!isLoggedIn"-->
                <!--                  :openRegisterDialog="this.openRegisterDialog"-->
                <!--                  :openLoginDialog="this.openLoginDialog"-->
                <!--                  :openLanguageDialog="this.openLanguageDialog"-->
                <!--                ></app-login-embedded>-->
                <!--                <v-btn v-if="isLoggedIn" icon :to="{ name: routeName.INBOX }">-->
                <!--                  <v-badge dot bottom left offset-x="5" offset-y="8" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">-->
                <!--                    <v-icon class="color-primary" size="32">email</v-icon>-->
                <!--                  </v-badge>-->
                <!--                </v-btn>-->

                <v-btn width="55" @click="openLanguageDialog()" v-if="!isLoggedIn" style="cursor:pointer;background-color: #DADADA;padding-left: 5px;">
                  <v-avatar size="25" class="language-button">
                    <img :src="`/static/image/country/${currentCurrency}.svg`" />
                  </v-avatar>
                  <v-icon class="largerIcon">
                    mdi-menu-down
                  </v-icon>
                </v-btn>
                <v-btn v-if="isLoggedIn" icon :to="{ name: routeName.INBOX }">
                  <v-badge dot bottom left offset-x="5" offset-y="8" :color="hasUnreadInboxMsg ? '#0080F6' : '#0080F6'">
                    <v-icon class="color-primary" size="32">email</v-icon>
                  </v-badge>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
        <!-- </v-toolbar> -->
      </v-col>
    </v-row>
    <v-navigation-drawer class="mobile-navigation-drawer" v-model="drawer" absolute left temporary>
      <v-row>
        <v-col cols="12" class="mobile-drawer-menu-header-section">
          <router-link class="mobile-drawer-menu-logo" :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }">
            <img class="mt-4" src="/static/svg/bb88_logo_animation2.gif" alt width="180" height="30" style="float: center" />
          </router-link>
          <v-icon class="mobile-drawer-menu-icon" @click.stop="drawer = !drawer">
            mdi-menu
          </v-icon>
        </v-col>
      </v-row>
      <v-divider class="my-4 mx-4"></v-divider>
      <v-list nav dense class="pb-16 mobileMenuListItem">
        <v-list-item-group active-class="grey-darken-1 text--accent-1">
          <v-list-item :to="{ name: routeName.PROMOTION, params: { lang: this.$route.params.lang } }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/promotion.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`mobileMenuTitle.promotion`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="currency.toUpperCase() === 'BDT'" :to="{ name: routeName.REWARD_STORE_ITEM, params: { lang: this.$route.params.lang } }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/rewards.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`mobileMenuTitle.rewards`) }}</v-col>
                  <v-col cols="2" class="text-center" style="margin-left: 5px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">
                    <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: routeName.REFERRAL_SUMMARY, params: { lang: this.$route.params.lang } }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/referAndEarn.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`mobileMenuTitle.referral`) }}</v-col>
                  <v-col cols="2" class="text-center" style="margin-left: 2px;  border-radius: 8px; color: white; font-size: 10px">
                    <v-img class="img-hot-home" src="/static/image/other/hot-icon.png" width="40"></v-img>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: routeName.VIP, params: { lang: this.$route.params.lang } }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/bettingPass.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <!-- <v-badge right overlap color="light" offset-x="5" offset-y="7">
                <template v-slot:badge>
                    <img src="/static/svg/game-icon-new.svg" height="20" width="20"/>
                    <span class="font-weight-bold" style="background-color:green;border-radius:15px;color:white; padding:2px; font-size:10px">  {{ $t(`label.new`) }} </span>
                </template>
                {{ $t(`label.vip`) }}
                </v-badge> -->
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`label.vip`) }}</v-col>
                  <!--                  <v-col cols="2" class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">-->
                  <!--                    <span class="font-weight-bold">{{ $t(`label.new`) }}</span>-->
                  <!--                  </v-col>-->
                  <v-col cols="2" class="text-center" style="margin-left: 2px;  border-radius: 8px; color: white; font-size: 10px">
                    <v-img class="img-hot-home" src="/static/image/other/hot-icon.png" width="40"></v-img>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: routeName.IPLVIP, params: { lang: this.$route.params.lang } }" v-if="isIPLEventActive">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/bpass_bpl_icon.png`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`label.iplVIP`) }}</v-col>
                  <v-col cols="2" class="text-center" style="margin-left: 2px;  border-radius: 8px; color: white; font-size: 10px">
                    <v-img class="img-hot-home" src="/static/image/other/hot-icon.png" width="40"></v-img>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="affLink" target="_blank">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/agentAff.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`mobileMenuTitle.agentAffiliate`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="currentCurrency === 'BDT'" :to="{ name: routeName.VIPTIER, params: { lang: this.$route.params.lang } }">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/bettingPass.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`label.vipText`) }}</v-col>
                  <v-col cols="2" class="text-center" style="margin-left: 2px;  border-radius: 8px; color: white; font-size: 10px">
                    <v-img src="/static/image/other/hot-icon.png" width="40"></v-img>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-4 mx-4"></v-divider>
        <span class="mobile-drawer-itemgroup-title">Games</span>
        <v-list-item-group active-class="grey-darken-1 text--accent-1">
          <v-list-item v-for="gameType in storageGameProviderType" :key="gameType.type" :to="getListItemTo(gameType)" exact>
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/${gameType.type}.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters justify="start" align="center">
                  <v-col cols="auto" class="mt-1">{{ $t(`label.${gameType.type}`) }}</v-col>
                  <v-col v-if="gameType.type == 'crash'" cols="2" class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">
                    <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-4 mx-4"></v-divider>
        <span class="mobile-drawer-itemgroup-title">Others</span>
        <v-list-item-group>
          <v-list-item @click="openLanguageDialog()">
            <v-list-item-icon>
              <v-btn icon small style="margin-left: -3px">
                <v-avatar size="25" class="language-button">
                  <!--                  <img :src="`/static/image/country/${currentCurrency}.svg`" />-->
                  <img :src="`/static/svg/mobileMenu/language.svg`" />
                </v-avatar>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`mobileMenuTitle.language`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: routeName.FAQ, params: { lang: this.$route.params.lang } }">
            <v-list-item-icon>
              <v-btn icon small style="margin-left: -3px">
                <v-avatar size="25">
                  <img :src="`/static/svg/mobileMenu/faq.svg`" />
                </v-avatar>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`mobileMenuTitle.faq`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="uihelper.getLiveChatUrl()" target="_blank">
            <v-list-item-icon>
              <v-btn icon small style="margin-left: -3px">
                <v-avatar size="25">
                  <img :src="`/static/svg/mobileMenu/liveChat.svg`" />
                </v-avatar>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`mobileMenuTitle.liveChat`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="downloadApk()">
            <v-list-item-icon>
              <v-btn icon small style="margin-left: -3px">
                <v-avatar size="25">
                  <img :src="`/static/svg/mobileMenu/downloadApp.svg`" />
                </v-avatar>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`mobileMenuTitle.downloadApp`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-4 mx-4"></v-divider>
        <v-list-item-group>
          <v-list-item @click="logout" v-if="isLoggedIn">
            <v-list-item-icon>
              <img :src="`/static/svg/mobileMenu/logout.svg`" alt width="25" height="25" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.logout`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-row class="hidden-sm-and-down header-top" no-gutters align="center" justify="space-between">
      <v-col cols="5" class="header-column">
        <router-link :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }">
          <!-- <img src="/static/image/logo/logo.png" class="brand-logo hidden-sm-and-down">
          <img src="/static/image/logo/logo.png" class="mobile-brand-logo hidden-md-and-up"> -->
          <img src="/static/svg/bb88_logo_animation2.gif" alt width="312" height="58" style="float: left" />
        </router-link>
      </v-col>

      <v-col cols="5" md="6" lg="5" class="header-column register-dialog text-right">
        <div v-if="isLoggedIn" class="greeting-text font-weight-bold">
          <label class="color-primary greeting-text-btn" @click="goToProfilePage()">
            <!--            {{ $t(`label.hi`) }},-->
            {{ memberInfo.memberCode }}
          </label>
          <!-- &nbsp;<span class="caption">({{ vipLevel }})</span> -->
          <!-- <img src="/static/svg/inbox.svg" alt style="float:center; height:50px; width=50px;" />
          <v-img src="/src/assets/svg/ld_icon.svg" style="float:center; height:50px; width=50px;" > </v-img> -->
          <v-btn class="circle" @click="goToProfilePage()">
            <img src="/static/image/other/vector.svg" alt="Vector Image" width="20" height="20" />
          </v-btn>
          <v-btn class="circle" :to="{ name: routeName.INBOX }">
            <img src="/static/image/other/notification.svg" alt="email image" width="20" height="20" />
            <v-badge top right offset-x="0" offset-y="0" style="z-index: 10;" :color="hasUnreadInboxMsg ? '#0080F6' : '#0080F6'">
              <template #badge>{{ numberofUnreadInboxMsg }}</template>
            </v-badge>
          </v-btn>
          <!--          <v-divider vertical></v-divider>-->
          <!--          <div class="vertical-line"></div>-->
          <!--          <v-btn icon :to="{ name: routeName.INBOX }" class="mr-4">-->
          <!--            <v-badge bottom left offset-x="6" offset-y="12" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">-->
          <!--              <v-icon size="48" class="color-primary">email</v-icon>-->
          <!--            </v-badge>-->
          <!--          </v-btn>-->
          <!-- <v-btn to="/bank/deposit" class="elevation-0"> -->
          <v-divider :thickness="3" style="" color="#D3D3D3" vertical></v-divider>
          <v-chip pill class="color-base header-wallet" @click="getMemberWallet()" :disabled="!notLoading">
            <!--            <label class="color-primary text-capitalize" v-if="notLoading">-->
            <!--              {{ memberWallet.totalBalance | currency(currentCurrency) }}-->
            <!--            </label>-->
            <!--            <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3"-->
            <!--                                 :size="24"></v-progress-circular>-->
            <label class="text-capitalize balance" v-if="notLoading">
              <span v-if="showBalance">{{ memberWallet.totalBalance | currency(currentCurrency) | maskNumber }}</span>
              <span v-else>{{ memberWallet.totalBalance | currency(currentCurrency) }}</span>
            </label>
            <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>
            <!--            <div @click="getMemberWallet()" class="refresh-balance-icon"></div>-->
          </v-chip>
          <!--          <v-icon x-large left @click="goToDepositPage()" class="color-primary header-wallet-icon mr-3" style="font-size: 50px; color: #FFCE01 !important;">-->
          <!--            add_circle-->
          <!--          </v-icon>-->
          <div style="position: relative; display: inline-block; ">
            <!--            <div style="position: absolute; top: 50%; right: -40%; transform: translate(-50%, -50%); background-color: black; width: 40px; height: 40px; border-radius: 50%;"></div>-->
            <v-icon x-large left @click="goToDepositPage()" class="color-primary header-wallet-icon" style="font-size: 50px; color: #0080F6 !important; z-index: 1;">
              add_circle
            </v-icon>
          </div>
          <!-- </v-btn> -->
          <v-btn class="pt-0 language_container" icon small @click="openLanguageDialog()">
            <div style="display: flex; align-items: center;">
              <v-avatar size="40" class="language-button">
                <img :src="`/static/image/country/${currentCurrency}.svg`" />
              </v-avatar>
              <v-icon>
                mdi-menu-down
                <!-- Down arrow icon -->
              </v-icon>
            </div>
          </v-btn>
          <v-btn class="elevation-0" @click="logout" small icon>
            <img :src="`/static/svg/mobileMenu/logout.svg`" style="fill: #3a3a3a" alt width="30" height="30" />
          </v-btn>
        </div>
        <app-login-embedded v-if="!isLoggedIn" :openRegisterDialog="this.openRegister" :openLoginDialog="this.openLogin" :openLanguageDialog="this.openLanguageDialog"></app-login-embedded>
      </v-col>
    </v-row>
    <!-- <v-row class="div_header hidden-sm-and-down">
      <v-col cols="11" class="splitter_header">
        <v-divider></v-divider>
      </v-col>
    </v-row> -->
    <app-page-navigator></app-page-navigator>

    <div class="body">
      <!--      <v-row no-gutters v-if="isLoggedIn" class="hidden-md-and-up mobile-header-detail" justify="space-between" align="center">-->
      <!--        <v-col cols="6">-->
      <!--          <label class="color-primary font-weight-bold mr-3">{{ $t(`label.hi`) }}, {{ memberInfo.memberCode }}</label>-->
      <!--        </v-col>-->
      <!--        <v-col cols="6" class="text-right">-->
      <!--          &lt;!&ndash; <v-btn to="/bank/deposit" class="elevation-0"> &ndash;&gt;-->
      <!--          <v-chip pill class="color-base header-wallet" @click="getMemberWallet()">-->
      <!--            <label class="color-primary text-capitalize font-weight-bold" v-if="notLoading">-->
      <!--              {{ memberWallet.totalBalance | currency(currentCurrency) }}-->
      <!--            </label>-->
      <!--            <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>-->
      <!--          </v-chip>-->
      <!--          <v-icon large left class="color-primary header-wallet-icon mr-0" @click="goToDepositPage()">add_circle</v-icon>-->
      <!--          &lt;!&ndash; </v-btn> &ndash;&gt;-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <router-view></router-view>
      <app-footer v-if="!hideDesktopFooer"></app-footer>
    </div>

    <div v-if="isLoggedIn && !hideBottomNavigator" class="mobile-navigator hidden-md-and-up">
      <v-row no-gutters>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.REFERRAL_TIER, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <img
                class="icon_btm_nav"
                alt
                :src="this.$route.name == routeName.REFERRAL_TIER ? '/static/svg/mobileBottomNavigation/referral_active.svg' : '/static/svg/mobileBottomNavigation/referral.svg'"
              />
              <p>{{ $t(`mobileMenuTitle.btmReferral`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.PROMOTION, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <img
                class="icon_btm_nav"
                alt
                :src="this.$route.name == routeName.PROMOTION ? '/static/svg/mobileBottomNavigation/promotion_active.svg' : '/static/svg/mobileBottomNavigation/promotion.svg'"
              />
              <p>{{ $t(`mobileMenuTitle.promotion`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <!-- <account-home class="icon_btm_nav"></account-home> -->
              <img class="icon_btm_nav" alt :src="this.$route.fullPath == '/home' ? '/static/svg/mobileBottomNavigation/home_active.svg' : '/static/svg/mobileBottomNavigation/home.svg'" />
              <p>{{ $t(`mobileMenuTitle.home`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.DEPOSIT, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <img class="icon_btm_nav" alt :src="this.$route.name == routeName.DEPOSIT ? '/static/svg/mobileBottomNavigation/deposit_active.svg' : '/static/svg/mobileBottomNavigation/deposit.svg'" />
              <p>{{ $t(`label.deposit`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.MOBILE_ACCOUNT, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <img
                class="icon_btm_nav"
                alt
                :src="this.$route.name == routeName.MOBILE_ACCOUNT ? '/static/svg/mobileBottomNavigation/account_active.svg' : '/static/svg/mobileBottomNavigation/account.svg'"
              />
              <p>{{ $t(`mobileMenuTitle.account`) }}</p>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="bottom-navigator d-md-none" v-if="!isTransferWalletLoginDialog && !hideBottomNavigator && !isLoggedIn">
      <v-row no-gutters v-if="!isLoggedIn" class="bottom-navigator-before-login">
        <v-col cols="6" @click="goToRegisterPage()" class="bottom-navigator-before-login-first d-flex justify-center align-center no-gutters">
          <b>{{ $t(`label.register`) }}</b>
        </v-col>
        <v-col cols="6" @click="goToLoginPage()" class="bottom-navigator-before-login-second d-flex justify-center align-center no-gutters">
          <b>{{ $t(`label.login`) }}</b>
        </v-col>
      </v-row>
    </div>

    <!-- WAIT REGISTER DIALOG -->
    <app-dialog :dialogShow="waitRegisterDialogShow" :max-width="600" :title="$t(`label.wait`)" :closeAction="closeWaitRegisterDialog">
      <app-wait-register :openRegisterDialog="openRegister" :openLoginDialog="openLogin"></app-wait-register>
    </app-dialog>

    <!-- REGISTER DIALOG -->
    <!--    <app-dialog-->
    <!--      :dialogShow="registerDialogShow"-->
    <!--      :max-width="400"-->
    <!--      :min-height="724"-->
    <!--      :title="$t(`label.signUp`)"-->
    <!--      :isAuth="false"-->
    <!--      :header_title="'register'"-->
    <!--      :openLoginDialog="openLogin"-->
    <!--      :closeAction="this.closeRegisterDialog"-->
    <!--    >-->
    <!--      <app-register :openLoginDialog="this.openLogin" :registerErrorMsg="this.registerErrorMsg"></app-register>-->
    <!--    </app-dialog>-->
    <app-dialog
      :dialogShow="registerDialogShow"
      :max-width="400"
      :min-height="724"
      :title="$t(`label.signUp`)"
      :isAuth="false"
      :header_title="'register'"
      :openLoginDialog="openLogin"
      :closeAction="this.closeRegisterDialog"
    >
      <pop-up-register :openLoginDialog="this.openLogin" :registerErrorMsg="this.registerErrorMsg"></pop-up-register>
    </app-dialog>

    <!-- FORGOT PASSWORD DIALOG -->
    <app-dialog :dialogShow="forgotPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeForgotPasswordDialog">
      <app-forgot-password :openRegisterDialog="openRegister"></app-forgot-password>
    </app-dialog>

    <!-- RESET PASSWORD DIALOG -->
    <app-dialog :dialogShow="resetPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeResetPasswordDialog">
      <app-reset-password :resetPasswordMemberCode="resetPasswordObj.memberCode" :resetPasswordContact="resetPasswordObj.contact"></app-reset-password>
    </app-dialog>

    <!-- LOGIN DIALOG -->
    <app-dialog
      :dialogShow="loginDialogShow"
      ref="loginDialog"
      :max-width="400"
      :title="$t(`label.login`)"
      :isAuth="false"
      :header_title="'login'"
      :openRegisterDialog="openRegister"
      :closeAction="this.closeLoginDialog"
    >
      <app-login :openRegisterDialog="this.openRegister" :loginErrorMsg="this.loginErrorMsg" :openForgotPasswordDialog="this.openForgotPasswordDialog" :closeAction="this.closeLoginDialog"></app-login>
    </app-dialog>

    <!-- REGISTER SUCCESS DIALOG -->
    <app-dialog
      :dialogShow="registerSuccessDialogShow"
      :isRegSuccess="true"
      ref="registerSuccessDialog"
      :max-width="400"
      :title="$t(`label.registerSuccess`)"
      :closeAction="this.closeRegisterSuccessDialog"
    >
      <app-register-success :closeRegisterSuccessDialog="closeRegisterSuccessDialog"></app-register-success>
    </app-dialog>

    <!--PT Quick Transfer Dialog-->
    <app-dialog :dialogShow="quickTransferDialogShow" :max-width="450" :title="this.transferMessage" :closeAction="this.closeQuickTransferDialog">
      <app-transfer-component :isNonBanking="true" :provider="this.transferToProvider" :ptGameInfo="this.ptGameInfo"></app-transfer-component>
    </app-dialog>

    <!-- PAGE DIALOG -->
    <app-dialog :dialogShow="pageDialogShow" :max-width="400" :title="pageDialog.title" :closeAction="this.pageDialog.dialogXButton">
      <app-page-dialog :dialogMessageTitle="pageDialog.messageTitle" :dialogMessage="pageDialog.message" :dialogButton="pageDialog.button" :dialogNoteMessage="pageDialog.note"></app-page-dialog>
    </app-dialog>

    <!-- INSTANT POPUP DIALOG -->
    <instant-popup-dialog :dialog-show="instantDialogShow" :max-width="438" :closeAction="this.closeInstantPopup" :array-info="popupAnnouncement"></instant-popup-dialog>

    <!-- LANGUAGE DIALOG -->
    <app-dialog :dialogShow="languageDialogShow" ref="languageDialog" :max-width="450" :title="'Currency and Language'" :isAuth="false" :header_title="'login'" :closeAction="this.closeLanguageDialog">
      <app-language :closeAction="this.closeLanguageDialog"></app-language>
    </app-dialog>

    <app-floating-chat v-if="currency === 'BDT'"></app-floating-chat>
    <app-loading-progress ref="loading"></app-loading-progress>
    <!--        <v-btn class="full-width font-weight-bold px-4 py-2 theme-button liveChatButton" height="auto"-->
    <!--               @click="openLiveChat">-->
    <!--            {{ $t(`label.liveChat`) }}-->
    <!--            <img :src="`${mediaUrl}/others_icons/live-chat.svg`" width="30" height="42"-->
    <!--                 class="btn_img_lc"/>-->
    <!--        </v-btn>-->
  </div>
</template>

<script>
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import AccountLiveChat from '@/assets/svg/liveChat.svg'
import AppTransferComponent from '@/views/bank/transfer'
import AccountProfile from '@/assets/svg/account-profile.svg'
import AccountWallet from '@/assets/svg/account-deposit.svg'
import AccountWithdrawal from '@/assets/svg/account-withdrawal.svg'
import AccountHistory from '@/assets/svg/account-history.svg'
import NavTransfer from '@/assets/svg/transfer.svg'
import NavDeposit from '@/assets/svg/deposit.svg'
import NavWithdrawal from '@/assets/svg/withdrawal.svg'
import NavLogout from '@/assets/svg/logout.svg'
import vipIcon from '@/assets/svg/vip.svg'
import InstantPopupDialog from '@/components/InstantPopupDialog.vue'

import { AnnouncementType, DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppRegister from '@/components/member/register.vue'
import AppRegisterSuccess from '@/components/member/registerSuccess.vue'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import AppWaitRegister from '@/components/member/waitRegister.vue'
import AppForgotPassword from '@/components/member/forgotPassword.vue'
import AppResetPassword from '@/components/member/resetPassword.vue'
import AppPageDialog from '@/components/layout/PageDialog.vue'
import AppPageNavigator from '@/components/layout/PageNavigator.vue'
import AppFooter from '@/components/layout/Footer.vue'
import AppLoadingProgress from '@/components/layout/LoadingProgress.vue'
import AppLogin from '@/components/member/login.vue'
import AppLanguage from '@/components/layout/Language.vue'
import { SESSION, SHARED, CACHE_KEY, SocialMediaContacts } from '@/constants/constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import * as eventBus from 'vue'
import {
  MEMBER_RESET_LOGIN_STORE,
  MEMBER_RESET_MOBILE_LOGIN_STORE,
  MEMBER_LOGOUT,
  MEMBER_REFRESH_TOKEN,
  MEMBER_RESET_LOGOUT_STORE,
  MEMBER_CHECK_TOKEN_VALIDITY,
  MEMBER_WALLET,
  MEMBER_RESET_FORGOT_PASSWORD_STORE,
  MEMBER_RESET_RESET_PASSWORD_STORE,
  MEMBER_CONTACT_VERIFICATION,
  MEMBER_RESET_CONTACT_VERIFICATION_STORE,
  MEMBER_RESET_REGISTER_STORE,
  MEMBER_GET_VIP_PROGRESS,
  MEMBER_RESET_SMS_VERIFICATION,
  MEMBER_GET_IPL_VIP_PROGRESS,
  MEMBER_RESET_GOTO_DEPOSIT,
  MEMBER_RESET_UPDATE_FORGOT_PASSWORD,
  ONE_SIGNAL_PLAYER_IDS_RESET_STORE,
  ONE_SIGNAL_PLAYER_IDS
} from '@/store/member.module'
import { POPUP_LIST } from '@/store/announcement.module'
import { WHEEL_MODULE } from '@/store/wheel.module'
import { INBOX_GET_MSG } from '@/store/inbox.module'
import { GET_IPL_EVENT_STATUS } from '@/store/member.module'
import AppFloatingChat from '@/components/home/FloatingLiveChat.vue'
import PopUpRegister from '@/components/member/popupRegister.vue'
import {AFFILIATE_IS_CUSTOM_REGISTER_PAGE_BANNER, AFFILIATE_REGISTER_PAGE_BANNER} from "@/store/affiliate.module";

export default {
  name: 'layoutMaster',
  components: {
    PopUpRegister,
    AppFloatingChat,
    AccountLiveChat,
    AppTransferComponent,
    AccountProfile,
    AccountWallet,
    AccountWithdrawal,
    AccountHistory,
    AppLogin,
    AppLanguage,
    AppRegister,
    AppRegisterSuccess,
    AppLoginEmbedded,
    AppForgotPassword,
    AppResetPassword,
    AppPageDialog,
    AppPageNavigator,
    AppFooter,
    AppLoadingProgress,
    AppWaitRegister,
    NavDeposit,
    NavWithdrawal,
    NavLogout,
    NavTransfer,
    vipIcon,
    InstantPopupDialog
  },
  data: () => ({
    showBalance: true,
    livechatLicensse: uiHelper.getLiveChatLicense(),
    isAndroidWebView: uiHelper.isAndroidWebView(),
    showDownloadAPK: true,
    isIos: uiHelper.IsIos(),
    instantDialogShow: false,
    instantPopupAnnouncement: [],
    drawer: false,
    notLoading: true,
    currentLanguage: uiHelper.getLanguage(),

    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    transferMessage: locale.getMessage(`label.ptQuickTransfer`, ['PT']),
    isTransferWalletLoginDialog: false,
    ptGameInfo: null,
    transferToProvider: 'PT',
    devicePlatform: DevicePlatform,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    uihelper: uiHelper,
    quickTransferDialogShow: false,
    registerSuccessDialogShow: false,
    waitRegisterDialogShow: false,
    registerDialogShow: false,
    loginDialogShow: false,
    forgotPasswordDialogShow: false,
    resetPasswordDialogShow: false,
    languageDialogShow: false,
    firstTimeRegister: true,
    routeName: ROUTE_NAME,
    resetPasswordObj: {
      contact: '',
      memberCode: ''
    },
    pageDialogShow: false,
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      note: '',
      message: [],
      button: []
    },
    memberLink: SHARED.MEMBER_HEADER_NAVIGATOR_LINK,
    loginErrorMsg: '',
    registerErrorMsg: '',
    currency: SHARED.DEFAULT_CURRENCY,
    currentCurrency: uiHelper.getCurrency()
  }),
  computed: {
    affLink() {
      switch (this.currency) {
        case 'BDT':
          return 'https://prelink.co/HxmKwzaLow'
        case 'INR':
          return 'https://babu88agents.com/in/'
        case 'NPR':
          return 'https://prelink.co/WeehAYitzj'
        default:
          return 'https://prelink.co/HxmKwzaLow'
      }
    },
    hideBottomNavigator() {
      let currentPage = this.$route.name
      let pagesToBHidden = [this.routeName.REGISTER, this.routeName.LOGIN]
      if (pagesToBHidden.indexOf(currentPage) != -1) {
        return true
      }
      return false
    },
    hideDesktopFooer() {
      let currentPage = this.$route.name
      let isDesktop = this.$vuetify.breakpoint.mdAndUp
      let pagesToBHidden = [
        this.routeName.REGISTER,
        this.routeName.LOGIN,
        this.routeName.DEPOSIT,
        this.routeName.WITHDRAWAL,
        this.routeName.MOBILE_ACCOUNT,
        this.routeName.REFERRAL_TIER,
        this.routeName.REFERRAL_SUMMARY,
        this.routeName.VIP,
        this.routeName.REFERRAL_SUMMARY_REPORT,
        this.routeName.REFERRAL_SUMMARY_HISTORY,
        this.routeName.MOBLE_HISOTRY,
        this.routeName.MOBILE_PROMO_HISTORY,
        this.routeName.MOBILE_TRANSACTION_HISTORY,
        this.routeName.MOBILE_TO_HISTORY,
        this.routeName.MOBILE_BETTING_HISTORY,
        this.routeName.CLAIM_VOUCHER,
        this.routeName.CHANGE_PASSWORD,
        this.routeName.PERSONAL,
        this.routeName.TURNOVER
      ]
      if (pagesToBHidden.indexOf(currentPage) != -1 && !isDesktop) {
        return true
      }
      return false
    },
    isIPLEventActive() {
      return this.$store.state.member.iplEventDate.status
    },
    popupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupAnnouncement
      k.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantPopup()
      }
      return p
    },
    hasUnreadInboxMsg() {
      return this.$store.state.inbox.inboxMsg.filter(m => !m.has_read).length > 0
    },
    numberofUnreadInboxMsg() {
      return this.$store.state.inbox.inboxMsg.filter(m => !m.has_read).length
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    wof() {
      return this.$store.state.wheel.wheel
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    loginResponseComplete() {
      return this.$store.state.member.loginResponse.complete
    },
    verifyContactResponseComplete() {
      return this.$store.state.member.verifyContactResponse.complete
    },
    changeForgotPasswordResponse() {
      return this.$store.state.member.changeForgotPassword.complete
    },
    resetPasswordResponseComplete() {
      return this.$store.state.member.resetPasswordResponse.complete
    },
    registerResponseComplete() {
      return this.$store.state.member.registerResponse.complete
    },
    forgotPasswordResponseComplete() {
      return this.$store.state.member.forgotPasswordResponse.complete
    },
    logoutResponseComplete() {
      return this.$store.state.member.logoutResponse.complete
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    vipLevel() {
      if (uiHelper.getCookie(SESSION.VIP) == null) {
        let m = this.$store.state.member.info
        let vip = m.group
        uiHelper.setCookie(SESSION.VIP, vip, 1)
        return vip
      }
      return uiHelper.getCookie(SESSION.VIP)
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipDepositProgress() {
      return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
    },
    vipRolloverProgress() {
      return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    ComregisterDialogShow() {
      return this.registerDialogShow
    },
    GotoDepositOnRegistration() {
      return this.$store.state.member.RegistrationLoginRedirect
    },
    registerPageBanner() {
      return this.$store.state.affiliate.registerPageBanner.complete
    }
  },
  watch: {
    changeForgotPasswordResponse() {
      //new forgotpassword flow
      let response = this.$store.state.member.changeForgotPassword
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.updateForgotPasswordCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_FORGOT_PASSWORD}`)
      }
    },
    GotoDepositOnRegistration() {
      let isRedirect = this.$store.state.member.RegistrationLoginRedirect
      if (isRedirect) {
        this.$store.dispatch(MEMBER_RESET_GOTO_DEPOSIT)
        let depositURL = this.$router.resolve({ name: ROUTE_NAME.DEPOSIT })
        window.location.href = depositURL.href
      }
    },
    ComregisterDialogShow() {
      if (!this.registerDialogShow) {
        //reset err msg once close popup
        this.registerErrorMsg = ''
      }
    },
    isLoggedIn() {
      if (this.isLoggedIn) {
        // this.getMemberWallet()
        this.getMemberVipProgress()
        this.getMemberIplVipProgress()
        this.getInboxMsg()
      }
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    memberInfo() {
      let m = this.$store.state.member.info
      let vip = m.group
      uiHelper.setCookie(SESSION.VIP, vip, 1)
      this.currentCurrency = uiHelper.getCurrency()

      // if(this.currentLanguage != m.preferredLanguage && !uiHelper.getCookie("changeLanguage")){
      //   uiHelper.removeCookie('language')
      //   uiHelper.setCookie('language', m.preferredLanguage)
      //   this.currentLanguage = m.preferredLanguage
      //   window.location.reload()
      // }
    },
    memberWallet() {
      this.notLoading = true
    },
    registerResponseComplete() {
      let response = this.$store.state.member.registerResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Register`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.registerResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_REGISTER_STORE}`)
      }
    },
    loginResponseComplete() {
      let response = this.$store.state.member.loginResponse
      /* Check Path Should Reload */
      if (this.$route.fullPath == '/sportsbook/bti') {
        window.location.reload()
      }
      if (response.complete) {
        try {
          this.$ga.event('Account', `Login`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        if (response.success) {
          this.setGoogleAnalyticUserId()
          this.oneSignal()
        }
        this.loginResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGIN_STORE}`)
        this.$store.dispatch(`${ONE_SIGNAL_PLAYER_IDS_RESET_STORE}`)
      }
    },
    logoutResponseComplete() {
      let response = this.$store.state.member.logoutResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Logout`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.logoutResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGOUT_STORE}`)
      }
    },
    verifyContactResponseComplete() {
      let response = this.$store.state.member.verifyContactResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Verify Contact`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.verifyContactResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_CONTACT_VERIFICATION_STORE}`)
      }
    },
    resetPasswordResponseComplete() {
      let response = this.$store.state.member.resetPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.resetPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_RESET_PASSWORD_STORE}`)
      }
    },
    forgotPasswordResponseComplete() {
      let response = this.$store.state.member.forgotPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Forgot Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.forgotPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_FORGOT_PASSWORD_STORE}`)
      }
    },
    registerPageBanner() {
      if (this.$store.state.affiliate.registerPageBanner.complete) {
        // Banner exists, update cookie. No point set cookie to non exist banner
        let data = this.$store.state.affiliate.registerPageBanner.data
        if (data && data.hash && data.image_urls) {
          // data.hash will be same value as rbanner query string
          const imageUrls = data.image_urls
          let mobileUrl = imageUrls.mobile
          let desktopUrl = imageUrls.desktop
          let existingHashFromCookie = uiHelper.getCookie(SESSION.REGISTER_PAGE_BANNER_HASH, data.hash, 1)
          if (!existingHashFromCookie || existingHashFromCookie !== data.hash) {
            uiHelper.setCookie(SESSION.REGISTER_PAGE_BANNER_HASH, data.hash, 1)
          }

          uiHelper.setCookie(SESSION.REGISTER_PAGE_BANNER_URL_DESKTOP, desktopUrl || mobileUrl, 1)
          uiHelper.setCookie(SESSION.REGISTER_PAGE_BANNER_URL_MOBILE, mobileUrl || desktopUrl, 1)

        } else {
          // Mark that not using custom register page banner as we failed to obtain banner image data
          this.$store.dispatch(`${AFFILIATE_IS_CUSTOM_REGISTER_PAGE_BANNER}`, false)

          uiHelper.removeCookie(SESSION.REGISTER_PAGE_BANNER_HASH)
          uiHelper.removeCookie(SESSION.REGISTER_PAGE_BANNER_URL_DESKTOP)
          uiHelper.removeCookie(SESSION.REGISTER_PAGE_BANNER_URL_MOBILE)
        }
      }
    }
  },
  mounted() {
    if (this.currency !== 'BDT') {
      // console.log('layoutmaster : livechat loaded id : '+window.__lc.license)
      //  window.__lc.license = 11862255
      //  console.log('layoutmaster : changed livechat  id to : '+window.__lc.license)
      window.__lc = window.__lc || {}
      window.__lc.license = this.livechatLicensse
      ;(function(n, t, c) {
        function i(n) {
          return e._h ? e._h.apply(null, n) : e._q.push(n)
        }

        var e = {
          _q: [],
          _h: null,
          _v: '2.0',
          on: function() {
            i(['on', c.call(arguments)])
          },
          once: function() {
            i(['once', c.call(arguments)])
          },
          off: function() {
            i(['off', c.call(arguments)])
          },
          get: function() {
            if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.")
            return i(['get', c.call(arguments)])
          },
          call: function() {
            i(['call', c.call(arguments)])
          },
          init: function() {
            var n = t.createElement('script')
            ;(n.async = !0), (n.type = 'text/javascript'), (n.src = 'https://cdn.livechatinc.com/tracking.js'), t.head.appendChild(n)
          }
        }
        !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e)
      })(window, document, [].slice)
    }
  },
  created() {
    this.checkHasSsoToken()
    this.checkRegisterPageBanner()
    this.getPopupAnnouncement()
    this.pageDialog = this.initializePageDialogProperty()
    if (localStorage.getItem(SESSION.TOKEN)) {
      this.getMemberVipProgress()
      this.getMemberIplVipProgress()
      this.validateMemberToken()
    }
    this.setGoogleAnalyticUserId()
    this.getInboxMsg()
    this.$eventHub.$on('open-page-dialog', dialog => {
      this.openPageDialog(dialog)
    })
    this.$eventHub.$on('close-page-dialog', unt => {
      this.closePageDialog()
    })
    this.$eventHub.$on('open-quick-transfer-dialog', gameInfo => {
      this.openQuickTransfer(gameInfo)
    })
    this.$eventHub.$on('close-quick-transfer-dialog', dialog => {
      this.closeQuickTransferDialog()
    })
    this.$eventHub.$on('open-login-dialog', obj => {
      if (obj != null) {
        this.isTransferWalletLoginDialog = obj.isTransfer
      }
      this.openLogin()
    })
    this.$eventHub.$on('close-login-dialog', dialog => {
      this.closeLoginDialog()
    })
    this.getIPLEventStatus()
  },
  beforeCreate() {
    this.$OneSignal.getUserId(result => {
      if (result == null) {
        localStorage.removeItem('onesignal-notification-prompt')
        this.$OneSignal.showSlidedownPrompt()
      } else {
        this.oneSignal()
      }
    })
    this.$OneSignal.on('subscriptionChange', result => {
      if (result) {
        this.oneSignal()
        console.log('subscribe status ', result)
      }
    })
  },
  methods: {
    handleVendorClick(provider) {
      if (!this.isGameMaintenance(this.providerSelected, provider.providerCode)) {
        this.goToVendor(provider.providerCode)
      }
    },
    maskBalance() {
      this.showBalance = !this.showBalance
    },
    getListItemTo(gameType) {
      let routeName = ''
      switch (gameType.type) {
        case 'sb':
          routeName = ROUTE_NAME.SPORTSBOOK
          break
        case 'rng':
          routeName = ROUTE_NAME.SLOT
          break
        case 'fishing':
          routeName = ROUTE_NAME.FISHING
          break
        case 'special':
          routeName = ROUTE_NAME.SPECIAL
          break
        case 'ld':
          routeName = ROUTE_NAME.CASINO
          break
        case 'table':
          routeName = ROUTE_NAME.TABLE_GAMES
          break
        case 'lottery':
          routeName = ROUTE_NAME.LOTTERY
          break
        case 'crash':
          routeName = ROUTE_NAME.CRASH
          break
        case 'cricket':
          routeName = ROUTE_NAME.CRICKET
          break
        default:
          routeName = ROUTE_NAME.HOME
          break
      }
      if (this.$route.params.lang) {
        // If it has a value, include it in the route
        return { name: routeName, params: { lang: this.$route.params.lang } }
      } else {
        // If not, just return the route without params
        return { name: routeName }
      }
    },
    hideDownloadBar() {
      this.showDownloadAPK = false
    },
    downloadApk() {
      uiHelper.downloadAPK()
    },
    getIPLEventStatus() {
      this.$store.dispatch(`${GET_IPL_EVENT_STATUS}`)
    },
    async oneSignal() {
      let data = {
        player_ids: await this.$OneSignal.getUserId(),
        is_subscribe: await this.$OneSignal.getSubscription(),
        permission_status: await this.$OneSignal.getNotificationPermission()
      }
      await this.$store.dispatch(`${ONE_SIGNAL_PLAYER_IDS}`, { data })
    },
    async getPopupAnnouncement() {
      let obj = {
        site: uiHelper.getPopup(),
        type: AnnouncementType.ALL,
        page: '',
        itemsPerPage: ''
      }
      await this.$store.dispatch(`${POPUP_LIST}`, { obj })
    },
    openInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        this.instantDialogShow = true
      }
    },
    closeInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popup', 'yes', 0.5)
      this.instantDialogShow = false
    },
    getInboxMsg() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${INBOX_GET_MSG}`)
      }
    },
    // navigateToCoinPage() {
    //   this.$router.push({
    //     name: ROUTE_NAME.GRAB_COIN,
    //     params: {
    //       type: 'coin'
    //     }
    //   })
    // },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, { filter })
    },
    checkShouldHideCoin() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_COIN) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_COIN) == 1) {
        this.showCoin = false
      } else {
        this.showCoin = true
      }
    },
    refreshCurrentState() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
      this.$forceUpdate()
    },
    navigateWOFPage() {
      if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
        this.$router.push({
          name: ROUTE_NAME.WOF
        })
      } else {
        this.$router.push({
          name: ROUTE_NAME.MWOF
        })
      }
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    checkShouldHideAngpao() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_ANG_PAO) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_ANG_PAO) == 1) {
        this.showAngpao = false
      } else {
        this.showAngpao = true
      }
    },
    dontShowMeAgain() {
      this.showAngpao = false
      uiHelper.setCookie(SESSION.HIDE_ANG_PAO, 1, 0.5)
    },
    navigateToAngpao() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'angpao'
        }
      })
    },
    setGoogleAnalyticUserId() {
      if (this.memberInfo.memberCode != '') this.$ga.set('userId', this.memberInfo.memberCode)
    },
    openLiveChat() {
      try {
        this.$ga.event('Live Chat', `Open`, `Open Live Chat`)
      } catch (err) {
        console.log(err)
      }
      window.open('/livechat', 'Live Chat', 'width=350,height=600')
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    },
    goToTransferPage() {
      this.$router.push({
        name: ROUTE_NAME.TRANSFER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToWithdrawalPage() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL,
        params: { lang: this.$route.params.lang }
      })
    },
    goToDepositPage() {
      // if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
      //   let routeData = this.$router.resolve({
      //     name: ROUTE_NAME.DEPOSIT
      //   })
      //   window.open(routeData.href)
      // } else {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT,
        params: { lang: this.$route.params.lang }
      })
      //}
    },
    goToProfilePage() {
      this.$router.push({
        name: ROUTE_NAME.PERSONAL,
        params: { lang: this.$route.params.lang }
      })
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    validateMemberToken() {
      if (new Date(parseInt(localStorage.getItem(SESSION.TOKEN_EXPIRY))) - new Date() < 60000) {
        if (new Date() - new Date(parseInt(localStorage.getItem(SESSION.LAST_ACTIVITY_TIME))) > 10800000) {
          const logoutObj = {}
          let sessionTimedOut = true
          this.$store.dispatch(MEMBER_LOGOUT, {
            logoutObj,
            sessionTimedOut
          })
        } else {
          this.$store.dispatch(MEMBER_REFRESH_TOKEN)
        }
      } else {
        let requiredLoading = false
        this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {
          requiredLoading
        })
      }
    },
    memberContactVerification(memberCode, otp, type, contact) {
      let verificationObj = {
        memberCode: memberCode,
        otp: otp,
        type: type,
        contact: contact
      }
      this.$store.dispatch(MEMBER_CONTACT_VERIFICATION, {
        verificationObj
      })
    },
    logout() {
      const logoutObj = {
        domain: uiHelper.getHostname(),
        platform: uiHelper.getPlatform()
      }
      let sessionTimedOut = false
      this.$store.dispatch(MEMBER_LOGOUT, {
        logoutObj,
        sessionTimedOut
      })
    },
    // getMemberWallet() {
    //   this.notLoading = false
    //   this.$store.dispatch(MEMBER_WALLET)
    // },
    getMemberWallet() {
      if (!this.lastDispatchTime || Date.now() - this.lastDispatchTime > 15 * 1000) {
        this.notLoading = false
        this.lastDispatchTime = Date.now() // Update last dispatch time
        this.$store.dispatch(MEMBER_WALLET)
      }
      this.maskBalance()
    },
    getMemberIplVipProgress() {
      this.$store.dispatch(MEMBER_GET_IPL_VIP_PROGRESS)
    },
    getMemberVipProgress() {
      this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
    },
    /* DIALOG */
    closeRegisterSuccessDialog() {
      this.registerSuccessDialogShow = false
    },
    closeQuickTransferDialog() {
      this.quickTransferDialogShow = false
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: this.ptGameInfo.provider,
          type: this.ptGameInfo.type
        },
        query: {
          id: this.ptGameInfo.id,
          code: this.ptGameInfo.code,
          demo: 0
        }
      })
      try {
        this.$ga.event('Game', `${0 ? 'Demo' : 'Real'} Play`, `${this.ptGameInfo.provider.toUpperCase()} - Slot - ${this.ptGameInfo.locale.find(x => x.language == 'en').name}`, this.ptGameInfo.id)
      } catch (err) {
        console.log(err)
      }
      uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
    },
    openRegisterDialog() {
      this.registerDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeLoginDialog()
      this.closeForgotPasswordDialog()
    },
    openRegister() {
      this.closeWaitRegisterDialog()
      this.closeLoginDialog()
      this.closeForgotPasswordDialog()
      this.$router.push({ name: ROUTE_NAME.REGISTER })
    },
    closeRegisterDialog(actionFromRegisterDialog = true) {
      this.registerDialogShow = false
      //this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      // if (this.firstTimeRegister && actionFromRegisterDialog) {
      //   this.openWaitRegisterDialog()
      //   this.firstTimeRegister = false
      // }
    },
    closeLoginDialog() {
      if (this.isTransferWalletLoginDialog) {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: 'all'
          }
        })
      }
      this.loginDialogShow = false
    },
    openForgotPasswordDialog() {
      this.forgotPasswordDialogShow = true
      this.closeLoginDialog()
    },
    closeForgotPasswordDialog() {
      this.forgotPasswordDialogShow = false
    },
    openResetPasswordDialog(memberCode, contact) {
      this.resetPasswordObj.memberCode = memberCode
      this.resetPasswordObj.contact = contact
      this.closeForgotPasswordDialog()
      this.resetPasswordDialogShow = true
    },
    closeResetPasswordDialog() {
      this.resetPasswordObj.memberCode = ''
      this.resetPasswordObj.contact = ''
      this.resetPasswordDialogShow = false
    },
    openLanguageDialog() {
      this.languageDialogShow = true
    },
    closeLanguageDialog() {
      this.languageDialogShow = false
    },
    openLoginDialog() {
      this.loginDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeRegisterDialog(false)
    },
    openLogin() {
      this.closeWaitRegisterDialog()
      this.closeRegisterDialog(false)
      this.$router.push({ name: ROUTE_NAME.LOGIN })
    },
    openQuickTransfer(gameInfo) {
      this.ptGameInfo = gameInfo
      this.quickTransferDialogShow = true
      this.transferToProvider = gameInfo.provider == 'pt' ? 'PT' : 'MAIN'
      this.transferMessage = locale.getMessage(`label.ptQuickTransfer`, [this.transferToProvider])
    },
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    closeTimeOutDialog() {
      localStorage.removeItem(SESSION.TOKEN)
      this.closePageDialog()
      this.refreshCurrentState()
    },
    closePageDialog() {
      this.pageDialogShow = false
      this.initializePageDialogProperty()
    },
    openWaitRegisterDialog() {
      this.waitRegisterDialogShow = true
    },
    closeWaitRegisterDialog() {
      this.waitRegisterDialogShow = false
    },
    /* RESPONSE COMPLETE DIALOG */
    loginResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`) + ' Error'
      let currentPage = this.$route.name

      if (response.code != 0 && !response.success) {
        this.loginErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
      } else {
        this.closeLoginDialog()

        this.currentCurrency = uiHelper.getCurrency()
        this.closeRegisterDialog(false)

        if (currentPage == this.routeName.LOGIN) {
          this.$router.push({
            name: ROUTE_NAME.HOME
          })
        }
      }
    },
    logoutResponseCompleteDialog(response) {
      if (response.isSessionTimedOut) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.${response.action}`)
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closeTimeOutDialog
        })
        this.openPageDialog(dialog)
      }
    },
    updateForgotPasswordCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.resetPassword`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    forgotPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.forgotPasswordSendText`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
        this.closeForgotPasswordDialog()
      }
      this.openPageDialog(dialog)
    },
    resetPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    closeResetPasswordSuccessDialog() {
      let self = this
      this.closePageDialog()
      setTimeout(function() {
        window.location.reload()
      }, 1)
    },
    registerResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      // console.log(this.$route.name +' , '+ this.$route.fullPath)

      if (response.code != 0 && !response.success) {
        // dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        // dialog.button.push({
        //   title: locale.getMessage(`label.close`),
        //   action: this.closePageDialog
        // })
        // this.openPageDialog(dialog)

        if (response.code == -1) {
          this.registerErrorMsg = locale.getMessage('fieldErrorMessage.invalidCaptchaCode')
        } else {
          this.registerErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
        }

        // dialog.title = locale.getMessage(`label.${response.action}`)+' Error'
        // dialog.message.push(this.registerErrorMsg)
        // let currentPage = this.$route.name
        // if(currentPage== this.routeName.REGISTER){
        //   this.openPageDialog(dialog, true)
        // }
      } else {
        let currentPage = this.$route.name
        if (currentPage != this.routeName.REGISTER) {
          // desktop view homepage popup registration
          this.registerSuccessDialogShow = true
          let $inObj = this
          setTimeout(function() {
            $inObj.registerSuccessDialogShow = false
            $inObj.$router.push({
              name: ROUTE_NAME.DEPOSIT
            })
          }, 5000)
        } else {
          //  mobile view full page registration
        }
      }
    },
    verifyContactResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.contactVerificationComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    checkHasSsoToken() {
      let s2 = this.$route.query.s2
      if (typeof s2 != 'undefined' && s2 !== '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, 1)
      }
      if (typeof this.$route.query.sso != 'undefined' && this.$route.query.sso != null && this.$route.query.sso != '') {
        localStorage.setItem(SESSION.TOKEN, this.$route.query.sso)
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT
        })
      }
    },
    checkRegisterPageBanner() {
      // url has ?rbanner=hash, load the banner information
      let rbanner = this.$route.query.rbanner || uiHelper.getCookie(SESSION.REGISTER_PAGE_BANNER_HASH)
      if (rbanner) {
        // Save into cookie first, there might be other checking/module that might update the url then redirect to other page
        uiHelper.setCookie(SESSION.REGISTER_PAGE_BANNER_HASH, rbanner, 1)

        // Mark that we are using custom register page banner to on hold display of banner in register page
        this.$store.dispatch(`${AFFILIATE_IS_CUSTOM_REGISTER_PAGE_BANNER}`, true)

        // On success will update and store in cookie
        this.$store.dispatch(`${AFFILIATE_REGISTER_PAGE_BANNER}`, { hash: rbanner })
      }
    }
  }
}
</script>

<style lang="scss">
.img-hot-home {
  width: 35px;
  animation: beat 1s infinite alternate;
}

.largerIcon {
  font-size: 35px !important;
}
.language_container {
  background-color: #d3d3d3;
  border-radius: 20px;
  width: 80px !important;
  height: 45px !important;
}
.iplList-item .v-list-item__content .v-list-item__title {
  white-space: break-spaces !important;
}
.vertical-line {
  width: 1px; /* Set the width to create a thin vertical line */
  height: 1.97rem; /* Set the height to span the height of its container */
  background-color: #d3d3d3; /* Set the color of the vertical line */
  margin: 0 10px; /* Add margin left and right for spacing */
}

.circle {
  min-width: 45px !important;
  width: 45px !important; /* Adjust the size of the circle */
  height: 45px !important; /* Adjust the size of the circle */
  border-radius: 50%; /* Makes the div circular */
  background-color: #ffce01 !important; /* Background color of the circle */
  cursor: pointer;
}

.circle img {
  max-width: 50%; /* Adjust the size of the image */
  max-height: 50%; /* Adjust the size of the image */
}
.download-bar {
  z-index: 5;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 12px;
  background-color: #f5f5f5;
  line-height: 1;

  .download-bar-button {
    background: var(--v-newMobilePrimary-base) !important;
    color: #333;
  }
}

.mobile-drawer-itemgroup-title {
  font-size: 1rem;
  color: #9b9b9b;
}

.mobile-drawer-menu-header-section {
  max-width: 256px;
  display: flex;
  padding-bottom: 6px !important;
}

.mobile-drawer-menu-icon {
  margin-left: auto;
  margin-top: auto;
}

.mobile-drawer-menu-logo {
  margin-left: auto;
}

.mobile-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-navigator {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  background-color: #45a67c;

  .bottom-navigator-before-login {
    height: 60px;
    background-color: #45a67c;

    .bottom-navigator-before-login-first {
      color: black;
      background-color: var(--v-newMobilePrimary-base);
      border: 1px solid #ddd;
    }

    .bottom-navigator-before-login-second {
      color: white;
      background-color: var(--v-newMobileSecondary-base);
    }

    .bottom-navigator-before-login-third {
      color: white;
      border: 1px solid var(--v-primary-base);
    }
  }
}

.mobileMenuListItem {
  //height: 100%;
  color: #9b9b9b !important;

  .v-list-item__title {
    color: #9b9b9b !important;
  }

  .v-avatar {
    border-radius: unset !important;
  }
}

.vip-badge-mobile {
  .v-badge {
    margin-top: 11px;
  }
}

.v-btn--is-elevated {
  box-shadow: 0px 0px !important;
}

.coin_box_gif {
  position: fixed;
  bottom: 29%;
  right: 10px;
  z-index: 200;
  cursor: pointer;
}

.grab_coin_icon {
  animation: swing 1.2s;
  animation-iteration-count: 3;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.color-primary {
  color: var(--v-primary-base) !important;
}

.color-base {
  background-color: var(--v-base-base) !important;
}

.color-base-yellow {
  color: var(--v-buttonPrimary-base) !important;
}

// .v-badge__badge {
//   bottom: 14px !important;
// }
.language_desktop {
  position: absolute;
  top: 70px;
  right: 3.5%;
}

.icon_middle_nav {
  width: 30px;
  stroke: white;
  fill: white;
}

.icon_btm_nav {
  width: 35px;
  height: 30px;
  margin-top: 8px;
  //stroke: #fff;
  fill: #fff;

  &.chat {
    stroke: white;
    fill: white;
    width: 23px;
    margin-bottom: -8px;
  }
}

.icon_normal {
  width: 25px;
  fill: white;
  color: black;
  stroke: #e4282f;
}

.icon_large {
  width: 35px;
  fill: white;
  color: black;
  margin-top: -5px;
}

.gold-gradient {
  background: transparent linear-gradient(180deg, #ffa15c 0%, #e2d200 100%) 0% 0% no-repeat padding-box;
  font-weight: bold;
}

.btn_img_lc {
  width: 30px;
  padding-bottom: 0;
  padding-left: 5px;
  margin-left: 10px;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
}

.gold-color {
  background-color: #b87953 !important;
}

.yellow-color {
  background-color: #fddd04 !important;
}

.v-btn.theme-button.liveChatButton {
  background: transparent linear-gradient(90deg, #fa786a 0%, #c83563 50%, #86286d 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
}

.mobile-member-info-box {
  padding: 5px 15px;
  background: #121734;
  box-shadow: 0px 3px 6px #00000042;
  border-radius: 8px;

  .mobile-member-info-box-text {
    font-size: 15px;
  }

  .mobile-member-info-box-text-icon {
    font-size: 12px;
  }

  .mobile-member-info-box-balance {
    font-size: 15px;
    font-weight: bold;
  }

  .mobile-member-info-box-icon {
    height: auto !important;

    .v-icon {
      font-size: 28px;
    }
  }
}

.liveChatButton {
  width: 200px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 1;
  min-height: auto !important;
  position: fixed;
  height: auto;
  top: 30%;
  right: -80px;
  transform: rotate(-90deg);

  span {
    font-size: 24px;
    font-weight: normal;
  }
}

.v-application--wrap {
  > div {
    height: 100%;
    background-color: #ebebeb;
  }
}

.header-row {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
}

button {
  &.round {
    border-radius: 50% !important;
  }

  &.buttonWhite {
    box-shadow: none;
  }

  span {
    letter-spacing: 0;
  }
}

.v-btn.primary-button {
  border-radius: 10px;
  color: black;
  text-transform: capitalize; // border-radius: 25px;
  width: 100%;
  font-size: 16px;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.body {
  padding-top: 0px;
  height: auto;
  /*min-height: 100%;*/
}

.brand-logo {
  position: absolute;
  left: 3%;
  transform: translateX(-3%);
  z-index: 1;
  top: -1%;
  width: 300px;
  margin-top: 20px;
}

.mobile-brand-logo {
  width: 250px;
  text-align: center;
}

.img_flag_select {
  width: 23px;
  height: 17px;
  margin-left: -10px;
  margin-right: 4px;
}

.member-menu-divider {
  border-color: white !important;
}

header {
  .v-toolbar__content {
    height: 100% !important;
  }
}

.div_header {
  background-color: #2d3487;
  padding-top: 20px;
}

.splitter_header {
  padding: 0;
  background-color: #3a3eb1;
  margin: 0 auto;
}

.header-icon-button-link {
  height: 40px !important;
  min-width: auto !important;
  width: 40px !important;
  margin-left: 8px;
}

.header-icon-button-more {
  height: 40px !important;
}

.header-text {
  font-size: 16px;
}

.header-top {
  min-height: 80px;
  color: #3a3a3a;
  background: #ffffff no-repeat padding-box;
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: space-between;

  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 38px;
    height: 30px;
    padding: unset;
  }

  &.mobile {
    min-height: 50px;
    // position: fixed;
    z-index: 2;
  }

  .greeting-text {
    //padding-left: 1em;
    font-size: 18px;
    display: flex;
    gap: 2%;
    justify-content: end;
    align-items: center;

    .greeting-text-btn {
      cursor: pointer;
    }
  }
}

.header-column {
  // display: flex;
  height: 100%;
  align-items: center; // margin-top: 10px;
  //margin-left: 50px;
  //margin-right: 30px; // margin-top: 20px;
  &.mobile {
    margin: 0 auto;
    height: 80px;
  }

  .header-wallet {
    border-radius: 20px;
    height: 45px !important;
    font-size: 20px;
    padding: 15px 35px 15px 25px;
  }

  .header-wallet-icon {
    margin-left: -40px;
  }
}

.account-actions {
  display: flex;

  a {
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
  }

  .flex-column {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

    img {
      height: 35px;
    }
  }
}

.login-form .v-messages__message {
  font-size: 9px !important;
}

.register-dialog {
  .row {
    form {
      display: flex;
      position: relative;
      align-items: baseline;

      .v-text-field__details {
        /*display: flex*/
      }

      .v-input {
        padding-right: 5px;
        min-width: 40px;
        max-width: 200px;

        .v-input__slot {
          background-color: #00000090;

          label {
            color: white;
          }

          input {
            color: white;
          }
        }
      }

      .v-input__control {
        margin-bottom: 0;
      }

      .login-button {
        width: 120px;
        padding: 9px !important;
      }

      .forgot-button {
        position: absolute;
        top: 65px;
        right: 37%;
        color: white;
      }

      .signup-button {
        color: black;
        border: 2px solid black;
        width: 110px;
        text-transform: capitalize; // border-radius: 25px;
      }
    }
  }
}

.header-bottom {
  min-height: 100px;
  color: #ffffff;
  background: transparent linear-gradient(180deg, #4145c4 0%, #121734 150%) 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0;
  z-index: 15;
}

.mobile-navigation-drawer {
  font-size: 12px;
  min-height: 100vh;
  position: absolute;

  .v-navigation-drawer__content {
    height: 100vh !important;
  }

  .header-wallet {
    font-size: 14px;
  }

  .vip-progress-bar {
    // color: var(--v-primary-base);
    // background-color: var(--v-base-base);
    .v-progress-linear__background {
      background-color: #ebebeb !important;
      border-color: #ebebeb !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      border-radius: 10px;
    }

    color: #3a3a3a;

    label {
      font-size: 11px;
      color: #acacac;
    }
  }

  .mobile-icon {
    fill: #fff !important;
  }

  p {
    font-size: 9px;
  }
}

.mobile-navigator {
  min-height: 40px;
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  background-color: #000;
  border-radius: 15px 15px 0 0;

  .theme--light.v-btn.v-btn--has-bg {
    background-color: transparent;
  }

  a {
    //height: 50px !important;
    letter-spacing: 1px;
    padding: 0 !important;
    display: block;
    border-color: var(--v-base_3-base);
    text-decoration: none;

    //&::before {
    //  opacity: 0 !important;
    //}

    // &.link-active {
    //   border-color: #8C8CDB;
    //   .icon_btm_nav {
    //     fill: #8C8CDB;
    //     stroke: #8C8CDB;
    //     ;
    //   }
    //   .v-icon {
    //     color: #8C8CDB;
    //   }
    //   p {
    //     color: #8C8CDB;
    //   }
    // }
    p {
      margin: -2px 0 0 0;
      text-transform: capitalize;
      color: #fff;
    }
  }
}

.mobile-header-detail {
  // margin-top: 36px !important;
  background-color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 12px;
  min-height: 46px;

  .header-wallet {
    font-size: 14px;
    padding: 15px 35px 15px 15px;
  }

  .header-wallet-icon {
    margin-left: -25px;
  }
}

@media (max-width: 1499px) {
  .div_header {
    padding-top: 0;
  }
  .header-top {
    min-height: 80px;
  }
  // .header-column {
  //   margin-top: -30px;
  // }
  .language_desktop {
    top: 60px;
    right: 4%;
  }
}

@media (max-width: 1263px) {
  .language_desktop {
    right: 6%;
  }
  .header-icon-button-link {
    height: 35px !important;
    width: 35px !important;
    margin-left: 4px;
  }
  .header-icon-button-more {
    height: 35px !important;
    margin-left: 4px;
    min-width: auto !important;
    width: 30px !important;

    .v-icon {
      height: 15px !important;
      font-size: 28px !important;
    }
  }
  .header-text {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .header-text-statis-word {
    display: none;
  }
}

@media (max-width: 959px) {
  .v-application--wrap {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .v-btn.primary-button {
    font-size: 12px;
    width: 100%;
  }
  .language_desktop {
    position: absolute;
    top: 50px;
    right: 13%;
  }
  .liveChatButton {
    display: none;
  }
  .body {
    padding-top: 0px;
  }
  .header {
    height: 50px;
    background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_3-base));
    padding: 0 15px;

    .mobile-brand-logo {
      width: auto !important;
      height: 25px !important;
      margin-top: 5px;
    }

    .header-mobile-livechat {
      position: absolute;
      top: 0;
      right: 15px;
      height: 100%;

      .v-icon {
        font-size: 25px;
        padding-right: 5px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }
  .v-btn.v-size--default {
    font-size: 14px;
  }
}

@media (max-width: 599px) {
  .header {
    padding: 0 15px;

    .header-mobile-livechat {
      .v-icon {
        font-size: 25px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }
  .mobile-brand-logo {
    margin-top: -10px;
    margin-left: -10px;
    width: 200px;
  }
  .profile-alert-success {
    font-size: 14px;
  }
  .profile-alert-failed {
    font-size: 14px;
  }

  .icon_btm_nav {
    width: 26px !important;
    height: 22px !important;
  }
}

@media (max-width: 425px) {
  .mobile-member-info-box {
    padding: 2px;
  }
  .header {
    .header-mobile-livechat {
      .v-icon {
        font-size: 22px;
      }

      .v-btn__content {
        font-size: 14px;
      }
    }
  }
  .mobile-navigator {
    a {
      .v-icon {
        font-size: 23px;
      }

      p {
        font-size: 11px;
      }
    }

    button {
      .v-icon {
        font-size: 28px;
      }

      p {
        font-size: 11px;
      }
    }
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

@keyframes beat {
  to {
    transform: scale(1.15);
  }
}
</style>
